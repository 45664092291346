import { memo, useState, useCallback, useEffect } from "react";
import Spinner from "../components/Spinner.js";
import Search from "../components/Search.js";
import { Grid, Button, Typography, Box, Tabs, Tab } from "@mui/material";
import Dropdown from "../components/Dropdown.js";
import { getPublicModels, getProjects, getPublicProjects } from "../api/index.js";
import ModelCard from "../components/ModelCard.js";
import ProjectCard from "../components/ProjectCard.js";
import { isFuzzyMatch, useSnackbar } from "../utils/index.js";
import CancelIcon from '@mui/icons-material/Cancel';
import { getDslsImages } from '../dsls/index.js';

const Marketplace = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [searchFilter, setSearchFilter] = useState("");
	const [selectedType, setSelectedType] = useState("All Types");
	const [selectedDsl, setSelectedDsl] = useState("All DSLs");
	const [publicModels, setPublicModels] = useState([]);
	const [filteredModels, setFilteredModels] = useState([]);
	const [userProjects, setUserProjects] = useState([]);
	const [publicProjectModels, setPublicProjectModels] = useState([]);
	const [userProjectsWithModels, setUserProjectsWithModels] = useState([]);
	const [selectedView, setSelectedView] = useState("models");
	const [publicProjects, setPublicProjects] = useState([]);
	const [dslsImages, setDslsImages] = useState([]);
	const [filteredProjects, setFilteredProjects] = useState([]);

	const { success, error } = useSnackbar();
	const typeOptions = [
		{ text: "All Types", value: "All Types" },
		{ text: "Editable", value: "Editable" },
		{ text: "Non-Editable", value: "Non-Editable" },
	];

	const dslOptions = [
		{ text: "All DSLs", value: "All DSLs" },
		{ text: "AppCreator", value: "AppCreator" },
		{ text: "EnvMaker", value: "EnvMaker" },
		{ text: "EnvPop", value: "EnvPop" },
		{ text: "SmAuto", value: "SmAuto" },
		{ text: "CODINTxt", value: "codintxt" },
		{ text: "OpenAPI", value: "OpenAPI" },
		{ text: "ToolBoxer", value: "ToolBoxer" },
		{ text: "Goal-dsl", value: "Goal-dsl" },
		{ text: "dflow", value: "dflow" },
		{ text: "DeMoL", value: "DeMoL" },
		{ text: "cps-ml", value: "cps-ml" },
		{ text: "Generos", value: "Generos" },
		{ text: "rosbridge-ml", value: "rosbridge-ml" },
		{ text: "XmasDSL", value: "XmasDSL" },
		{ text: "comm-idl", value: "comm-idl" },
	];

	const textualDslOptions = [
		{ text: "All DSLs", value: "All DSLs" },
		{ text: "SmAuto", value: "SmAuto" },
		{ text: "CODINTxt", value: "CODINTxt" },
		{ text: "OpenAPI", value: "OpenAPI" },
		{ text: "ToolBoxer", value: "ToolBoxer" },
		{ text: "Goal-dsl", value: "Goal-dsl" },
		{ text: "dflow", value: "dflow" },
		{ text: "DeMoL", value: "DeMoL" },
		{ text: "cps-ml", value: "cps-ml" },
		{ text: "Generos", value: "Generos" },
		{ text: "rosbridge-ml", value: "rosbridge-ml" },
		{ text: "XmasDSL", value: "XmasDSL" },
		{ text: "comm-idl", value: "comm-idl" },
	];

	const graphicalDslOptions = [
		{ text: "All DSLs", value: "All DSLs" },
		{ text: "AppCreator", value: "AppCreator" },
		{ text: "EnvMaker", value: "EnvMaker" },
		{ text: "EnvPop", value: "EnvPop" },
	];

	const fetchData = useCallback(async () => {
		setIsLoading(true);
		// Gets the public models
		const { models } = await getPublicModels();

		// Gets the ids for projects in which the models were published
		const publishedInProjectIds = models.map((model) => model.publishedInProject);

		// Gets the published projects
		const { publishedProjects } = await getPublicProjects(publishedInProjectIds);
		setPublicProjects(publishedProjects);

		// Creates a map of userIds to fullnames for the creators of the models
		const creatorFullNames = models.reduce((acc, model) => {
			acc[model.creator._id] = model.creator.fullname;
			return acc;
		}, {});

		// Creates a map of projectId and the whole model objects it contains
		const tmpPublicProjectModels = models.filter((model) => model.publishedInProject);
		const groupedByProject = tmpPublicProjectModels.reduce((acc, model) => {
			const projectId = model.publishedInProject;
			if (!acc[projectId]) {
				acc[projectId] = [];
			}

			acc[projectId].push(model);
			return acc;
		}, {});

		// Get the user projects to use them in the clone project popup
		const projects = await getProjects();
		setUserProjects(projects);

		// Sets the public models and the map of the grouped by projectId models
		setPublicModels(models);
		setPublicProjectModels(groupedByProject);

		// Adds the full name of the project creators using the creatorfullnames map
		const projectsWithFullNames = publishedProjects.map((project) => {
			const creatorFullName = creatorFullNames[project.owner];

			return {
				...project,
				creatorFullName,
			};
		});

		setUserProjectsWithModels(projectsWithFullNames);

		const _dslsImages = await getDslsImages();
		setDslsImages(_dslsImages);

		setIsLoading(false);
	}, []);

	useEffect(() => {
		fetchData();
	}, []);

	const clearFilters = () => {
		setSearchFilter("");
		setSelectedType("All Types");
		setSelectedDsl("All DSLs");
	};

	useEffect(() => {
		let filtered = [...publicModels];
		let filteredPrjs = [...userProjectsWithModels];

		if (searchFilter) {
			filtered = filtered.filter((flt) => isFuzzyMatch(flt?.title, searchFilter));
			filteredPrjs = filteredPrjs.filter((flt) => isFuzzyMatch(flt?.title, searchFilter));
		}

		if (selectedType && selectedType !== "All Types") {
			if (selectedType === "Editable") {
				filtered = filtered.filter((model) => model.shopEditable === true);
			}

			if (selectedType === "Non-Editable") {
				filtered = filtered.filter((model) => model.shopEditable === false);
			}
		}

		if (selectedDsl && selectedDsl !== "All DSLs") {
			filtered = filtered.filter((model) => model.model_type === selectedDsl.toLowerCase().replaceAll('-', ""));
		}

		setFilteredModels(filtered);
		setFilteredProjects(filteredPrjs);
	}, [searchFilter, selectedType, selectedDsl, publicModels]);

	const handleTabChange = (_, newValue) => {
		setSelectedView(newValue);
	};

	return (
		<>
			<Spinner open={isLoading} />
			<Grid
				container
				display="flex"
				direction="column"
				alignItems="center"
				justifyContent="center"
			>
				<Grid
					container
					display="flex"
					direction="column"
					alignItems="center"
					justifyContent="center"
				>
					<Grid container width="100%" flexDirection="row" justifyContent="center">
						<Grid container item width="100%" xs={9} justifyContent="center">
							<Grid
								container
								item
								width="75%"
								pr={2}
								mb={2}
								display="flex"
								minHeight="60px"
								alignItems="center"
								justifyContent="center"
								sx={{}}
							>
								<Grid
									item
									display="flex"
									alignItems="end"
									justifyContent="center"
									xs={12}
								>
									<Tabs
										value={selectedView}
										variant="fullWidth"
										aria-label="view selection tabs"
										sx={{
											borderColor: "#ABC7D9 !important",
											borderBottom: 0.2,
											borderWidth: "1px",
											"& .MuiTabs-indicator": {
												backgroundColor: "white !important",
												height: "4px",
											},
											width: "60%",
										}}
										onChange={handleTabChange}
									>
										<Tab
											label="Models"
											value="models"
											sx={{
												color: selectedView === "models" ? "white!important" : "#ABC7D9 !important",
												fontWeight: selectedView === "models" ? "bold" : "normal",
												fontSize: selectedView === "models" ? "1.1rem" : "1rem",
												textTransform: "none",
											}}
										/>
										<Tab
											label="Projects"
											value="projects"
											sx={{
												color: selectedView === "projects" ? "white!important" : "#ABC7D9 !important",
												fontWeight: selectedView === "projects" ? "bold" : "normal",
												fontSize: selectedView === "projects" ? "1.1rem" : "1rem",
												textTransform: "none",
											}}
										/>
									</Tabs>
								</Grid>
							</Grid>
						</Grid>
					</Grid>

					<Grid container width="100%" flexDirection="row" justifyContent="center">
						<Grid container item width="100%" xs={9} justifyContent="center">
							<Grid
								container
								item
								width="75%"
								pr={2}
								mb={2}
								display="flex"
								alignItems="center"
								justifyContent="space-between"
								sx={{
									borderBottom: 0.1,
									borderColor: "secondary.main",
									height: "60px",
								}}
							>
								<Grid
									container
									item
									xs={12}
									display="flex"
									alignItems="center"
									justifyContent="space-between"
								>
									{/* Dropdown Filters */}
									{selectedView !== "projects" && (
										<>
											<Grid
												item
												xs={2}
												sx={{ maxWidth: "150px" }}
												display="flex"
												alignItems="center"
												justifyContent="center"
											>
												<Dropdown
													id="type"
													placeholder="All Types"
													items={typeOptions}
													value={selectedType}
													filled={false}
													background="primary"
													sx={{ borderColor: "#193256" }}
													onChange={(event) => setSelectedType(event.target.value)}
												/>
											</Grid>
											<Grid
												item
												xs={2}
												sx={{ maxWidth: "150px" }}
												display="flex"
												alignItems="center"
												justifyContent="center"
											>
												<Dropdown
													id="dsls"
													placeholder="All DSLs"
													items={dslOptions}
													value={selectedDsl}
													filled={false}
													background="primary"
													sx={{
														borderColor: "#193256",
													}}
													onChange={(event) => setSelectedDsl(event.target.value)}
												/>
											</Grid>
											<Grid
												item
												xs={3}
												display="flex"
												alignItems="center"
												justifyContent="center"
											>
												<Button
													variant="outlined"
													color="secondary"
													sx={{
														borderRadius: "20px",
														textTransform: "none",
														fontWeight: "bold",
														display: "flex",
														alignItems: "center",
														fontSize: "0.65rem",
													}}
													endIcon={<CancelIcon sx={{ fontSize: "1rem" }} />}
													onClick={clearFilters}
												>
													{"Clear Filters"}
												</Button>
											</Grid>
										</>
									)}
									<Grid
										item
										xs={selectedView === "projects" ? 12 : 5}
										sx={{ maxWidth: 300 }}
										display="flex"
										alignItems="center"
										justifyContent="center"
									>
										<Search
											value={searchFilter}
											color="#DDE8EF"
											width={selectedView === "projects" ? 500 : "100%"}
											onChange={(event) => setSearchFilter(event.target.value)}
										/>
									</Grid>
								</Grid>
							</Grid>

						</Grid>
					</Grid>
				</Grid>

				<Grid
					container
					spacing={2}
					mt={2}
					justifyContent="center"
					sx={{ width: 1400 }}
				>
					{selectedView === "models"
						&& filteredModels.map((model, index) => (
							<Grid
								key={index}
								item
								xs={10}
								sm={6}
								md={3}
								lg={3}
								display="flex"
								justifyContent="center"
							>
								<Box sx={{ width: 300 }}>
									<ModelCard
										model={model}
										setIsLoading={setIsLoading}
										success={success}
										error={error}
										fetchData={fetchData}
										textualDsls={textualDslOptions.map((dsl) => dsl.value.toLowerCase())}
										graphicalDsls={graphicalDslOptions.map((dsl) => dsl.value.toLowerCase())}
										projects={userProjects}
										publicProjects={publicProjects}
										dslImage={dslsImages[model?.model_type.toLowerCase().replaceAll('-', "")]}
									/>
								</Box>
							</Grid>
						))}

					{selectedView === "projects"
						&& filteredProjects.map((project, index) => (
							<Grid
								key={index}
								item
								xs={10}
								sm={6}
								md={3}
								lg={3}
								display="flex"
								justifyContent="center"
							>
								<Box sx={{ width: 300 }}>
									<ProjectCard
										project={project}
										setIsLoading={setIsLoading}
										success={success}
										error={error}
										fetchData={fetchData}
										projectModels={publicProjectModels[project._id]}
										textualDslOptions={textualDslOptions}
										graphicalDslOptions={graphicalDslOptions}
										dslsImages={dslsImages}
										userProjects={userProjects.map((prj) => prj._id)}
									/>
								</Box>
							</Grid>
						))}
				</Grid>

				{filteredModels.length === 0 && selectedView === "models" && !isLoading && (
					<Typography variant="h6" color="white !important" mt={4}>
						{"No models found."}
					</Typography>
				)}

				{filteredProjects.length === 0 && selectedView === "projects" && !isLoading && (
					<Typography variant="h6" color="white !important" mt={4}>
						{"No projects found."}
					</Typography>
				)}
			</Grid>
		</>
	);
};

export default memo(Marketplace);
