import { memo, useCallback, useEffect, useState } from "react";

import { Box, Grid, Typography, TextField } from "@mui/material";
import Chip from '@mui/material/Chip';

import MDEditor from "@uiw/react-md-editor";

import ProjectCard from "../components/ProjectCard.js";

import Popup from "../components/Popup.js";
import Search from "../components/Search.js";
import Spinner from "../components/Spinner.js";
import { isFuzzyMatch, useSnackbar } from "../utils/index.js";

import { makeStyles } from '@mui/styles';

import { getProjects, newProject } from "../api/index.js";

const useStyles = makeStyles({
	buttonCursor: {
		cursor: "pointer",
	},
	select: {
		color: "#F1A542",
		"&:before": { borderColor: "#F1A542" },
		"&:after": { borderColor: "#F1A542" },
	},
});

const Projects = () => {
	const { success, error } = useSnackbar();
	const [isLoading, setIsLoading] = useState(false);
	const [searchFilter, setSearchFilter] = useState("");
	const [projects, setProjects] = useState([]);
	const [filtered, setFiltered] = useState(projects);
	const [addProjectPopupOpen, setAddProjectPopupOpen] = useState(false);
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");

	const classes = useStyles();

	const fetchData = useCallback(
		async () => {
			setIsLoading(true);

			const prjs = await getProjects();
			const sortedProjects = prjs.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

			setProjects(sortedProjects);
			setIsLoading(false);
		},
		[],
	);

	useEffect(() => {
		(async () => {
			await fetchData();
		})();
	}, [fetchData]);

	useEffect(() => {
		setFiltered(projects.filter((us) => isFuzzyMatch(us.title, searchFilter)));
	}, [searchFilter, projects]);

	const addProjectSubmitHandler = async () => {
		setIsLoading(true);
		try {
			const { success: successCode, message } = await newProject(title, description);
			if (successCode) {
				success(message);
			} else {
				error(message);
			}
			setAddProjectPopupOpen(false);
			setTitle("");
			setDescription("");
		} catch { /* empty */ }
		await fetchData();
		setIsLoading(false);
	};


	return (
		<>
			<Spinner open={isLoading} />
			<Popup
				width="800px"
				open={addProjectPopupOpen}
				title="Edit project information"
				onClose={() => {
					setAddProjectPopupOpen(false);
				}}
			>
				<TextField
					label="Title"
					variant="filled"
					sx={{
						width: "100%",
						mb: 2
					}}
					InputProps={{
						style: { color: 'white' },
					}}
					value={title}
					onChange={(e) => {
						setTitle(e.target.value);
					}}
				/>
				<Typography
					sx={{
						color: "#bbb",
						fontSize: "0.9rem",
						mb: 1,
						pl: 0.5,
					}}
				>
					{"Description in MarkDown format:"}
				</Typography>
				<MDEditor
					hideToolbar
					value={description}
					height={500}
					maxHeight={1200}
					fullscreen={false}
					tabSize={2}
					style={{
						zIndex: 100,
						borderRadius: 10,
					}}
					onChange={setDescription}
				/>
				{/* Add button to submit broker */}
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-end",
						mt: 2,
					}}
				>
					<Chip
						label="Submit"
						color="secondary"
						className={classes.clickableChip}
						sx={{ mr: 0.5 }}
						onClick={addProjectSubmitHandler}
					/>
				</Box>
			</Popup>
			<Grid
				container
				direction="column"
				alignItems="center"
				justifyContent="center"
			>
				<Grid
					container
					width="100%"
					flexDirection="row"
					justifyContent="center"
				>
					<Grid
						container
						item
						width="100%"
						xs={9}
						justifyContent="center"
					>
						<Grid
							container
							item
							width="100%"
							pr={2}
							mb={2}
							display="flex"
							minHeight="60px"
							alignItems="center"
							justifyContent="space-between"
							sx={{
								borderBottom: 0.1,
								borderColor: "secondary.main",
							}}
						>
							<Grid
								container
								item
								width="100%"
								pl={2}
								xs={7}
								display="flex"
								minHeight="50px"
								alignItems="center"
							>
								<Grid
									item
									xs={9}
								>
									<Typography variant="h6" color="#ffffff">
										{"Your Projects"}
									</Typography>
								</Grid>
								<Grid
									item
									xs={3}
									display="flex"
									flexDirection="row"
									alignItems="center"
									justifyContent="flex-end"
									pr={1}
								>
									<Chip
										label="+"
										color="success"
										sx={{
											mr: 0.5,
										}}
										onClick={() => setAddProjectPopupOpen(true)} />
								</Grid>
							</Grid>
							<Grid
								item
								xs={4}
							>
								<Search value={searchFilter} onChange={(event) => setSearchFilter(event.target.value)} />
							</Grid>
						</Grid>

						{filtered.length === 0 && (
							<Typography color="gray" pl={2} pb={1} pt={1} fontSize="0.8rem">
								{"No projects found."}
							</Typography>
						)}
						<Grid
							container
							spacing={3}
							mt={3}
							justifyContent="flex-start"
							alignItems="stretch"
							sx={{ width: "100%", maxWidth: 1400, margin: "0 auto" }}
						>
							{filtered.map((e, ind) => (
								<Grid
									key={ind}
									item
									xs={12}
									sm={6}
									md={4}
									lg={3}
									display="flex"
								>
									<Box sx={{ width: "100%", maxWidth: 320 }}>
										<ProjectCard
											project={e}
											setIsLoading={setIsLoading}
											success={success}
											error={error}
											fetchData={fetchData}
											userProjects={filtered.map((fltr) => fltr?._id)}
											isFromProjectScreen={true}
										/>
									</Box>
								</Grid>
							))}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

export default memo(Projects);
