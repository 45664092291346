import barcodeIcon from "../../../assets/images/missionItems/actors/barcode.png";
import colorIcon from "../../../assets/images/missionItems/actors/color_border.png";
import fireIcon from "../../../assets/images/missionItems/actors/fire.png";
import humanIcon from "../../../assets/images/missionItems/actors/human_icon.png";
import humanMoving from "../../../assets/images/missionItems/actors/human_icon_moving.png";
import humanMovingSpeaking from "../../../assets/images/missionItems/actors/human_icon_moving_speaking.png";
import humanSpeaking from "../../../assets/images/missionItems/actors/human_icon_speaking.png";
import qrIcon from "../../../assets/images/missionItems/actors/qr.png";
import poiIcon from "../../../assets/images/missionItems/actors/poi.png";
import poiEdge from "../../../assets/images/missionItems/actors/poi_edge.png";
import rfidIcon from "../../../assets/images/missionItems/actors/rfid.png";
import robotNormalRedIcon from "../../../assets/images/missionItems/general/robotNormalRed.png";
import robotNormalGreenIcon from "../../../assets/images/missionItems/general/robotNormalGreen.png";
import robotNormalBlueIcon from "../../../assets/images/missionItems/general/robotNormalBlue.png";
import robotNormalOrangeIcon from "../../../assets/images/missionItems/general/robotNormalOrange.png";
import robotNormalGreyIcon from "../../../assets/images/missionItems/general/robotNormalGrey.png";
import robotScientistRedIcon from "../../../assets/images/missionItems/general/robotScientistRed.png";
import robotScientistGreenIcon from "../../../assets/images/missionItems/general/robotScientistGreen.png";
import robotScientistBlueIcon from "../../../assets/images/missionItems/general/robotScientistBlue.png";
import robotScientistOrangeIcon from "../../../assets/images/missionItems/general/robotScientistOrange.png";
import robotScientistGreyIcon from "../../../assets/images/missionItems/general/robotScientistGrey.png";
import robotFormulaRedIcon from "../../../assets/images/missionItems/general/robotFormulaRed.png";
import robotFormulaGreenIcon from "../../../assets/images/missionItems/general/robotFormulaGreen.png";
import robotFormulaBlueIcon from "../../../assets/images/missionItems/general/robotFormulaBlue.png";
import robotFormulaOrangeIcon from "../../../assets/images/missionItems/general/robotFormulaOrange.png";
import robotFormulaGreyIcon from "../../../assets/images/missionItems/general/robotFormulaGrey.png";
import robotMiniRedIcon from "../../../assets/images/missionItems/general/robotMiniRed.png";
import robotMiniGreenIcon from "../../../assets/images/missionItems/general/robotMiniGreen.png";
import robotMiniBlueIcon from "../../../assets/images/missionItems/general/robotMiniBlue.png";
import robotMiniOrangeIcon from "../../../assets/images/missionItems/general/robotMiniOrange.png";
import robotMiniGreyIcon from "../../../assets/images/missionItems/general/robotMiniGrey.png";
import robotTractorRedIcon from "../../../assets/images/missionItems/general/robotTractorRed.png";
import robotTractorGreenIcon from "../../../assets/images/missionItems/general/robotTractorGreen.png";
import robotTractorBlueIcon from "../../../assets/images/missionItems/general/robotTractorBlue.png";
import robotTractorOrangeIcon from "../../../assets/images/missionItems/general/robotTractorOrange.png";
import robotTractorGreyIcon from "../../../assets/images/missionItems/general/robotTractorGrey.png";
import robotDroneRedIcon from "../../../assets/images/missionItems/general/robotDroneRed.png";
import robotDroneGreenIcon from "../../../assets/images/missionItems/general/robotDroneGreen.png";
import robotDroneBlueIcon from "../../../assets/images/missionItems/general/robotDroneBlue.png";
import robotDroneOrangeIcon from "../../../assets/images/missionItems/general/robotDroneOrange.png";
import robotDroneGreyIcon from "../../../assets/images/missionItems/general/robotDroneGrey.png";
import robotRoombaRedIcon from "../../../assets/images/missionItems/general/robotRoombaRed.png";
import robotRoombaGreenIcon from "../../../assets/images/missionItems/general/robotRoombaGreen.png";
import robotRoombaBlueIcon from "../../../assets/images/missionItems/general/robotRoombaBlue.png";
import robotRoombaOrangeIcon from "../../../assets/images/missionItems/general/robotRoombaOrange.png";
import robotRoombaGreyIcon from "../../../assets/images/missionItems/general/robotRoombaGrey.png";
import soundIcon from "../../../assets/images/missionItems/actors/sound.png";
import textIcon from "../../../assets/images/missionItems/actors/text.png";
import wallIcon from "../../../assets/images/missionItems/actors/wall.png";
import wallEdge from "../../../assets/images/missionItems/actors/wall_edge.png";
import waterIcon from "../../../assets/images/missionItems/actors/water.png";

const general = {
	title: "General",
	items: {
		barcode: {
			icon: barcodeIcon,
			variables: {
				message: {
					type: "text",
					name: "Message",
					value: "",
					placeholder: "Message",
					tooltipDisplay: true,
				},
			},
			tooltip: {
				header: "Barcode",
				body: "A barcode component with a message",
			},
		},
		color: {
			icon: colorIcon,
			variables: {
				r: {
					type: "number",
					name: "Red",
					value: 255,
					min: 0,
					max: 255,
					step: 1,
					precision: 0,
					tooltipDisplay: false,
					metersDisplay: false,
				},
				g: {
					type: "number",
					name: "Green",
					value: 255,
					min: 0,
					max: 255,
					step: 1,
					precision: 0,
					tooltipDisplay: false,
					metersDisplay: false,
				},
				b: {
					type: "number",
					name: "Blue",
					value: 255,
					min: 0,
					max: 255,
					step: 1,
					precision: 0,
					tooltipDisplay: false,
					metersDisplay: false,
				},
			},
			tooltip: {
				header: "Color",
				body: "A simulated area with a specific dominant color",
			},
		},
		fire: {
			icon: fireIcon,
			variables: {
				range: {
					type: "number",
					name: "Range",
					value: 20,
					min: 0,
					max: 1000,
					step: 1,
					precision: 1,
					format: "m",
					tooltipDisplay: false,
					metersDisplay: true,
				},
				temperature: {
					type: "number",
					name: "Temperature",
					value: 150,
					min: 40,
					max: 1000,
					step: 1,
					precision: 1,
					format: "°C",
					tooltipDisplay: false,
					metersDisplay: false,
				},
			},
			tooltip: {
				header: "Fire",
				body: "A source of fire, denoting large temperatures",
			},
		},
		human: {
			icon: humanIcon,
			variables: {
				move: {
					type: "boolean",
					name: "Move",
					value: false,
					tooltipDisplay: false,
					metersDisplay: false,
				},
				sound: {
					type: "boolean",
					name: "Sound",
					value: false,
					tooltipDisplay: false,
					metersDisplay: false,
				},
				language: {
					type: "select",
					name: "Language",
					value: "EL",
					options: ["EL", "EN"],
					tooltipDisplay: false,
					metersDisplay: false,
				},
				range: {
					type: "number",
					name: "Range",
					value: 20,
					min: 0,
					max: 1000,
					step: 1,
					precision: 1,
					format: "m",
					tooltipDisplay: false,
					metersDisplay: true,
				},
				speech: {
					type: "text",
					name: "Speech",
					value: "",
					placeholder: "Speech",
					tooltipDisplay: false,
					metersDisplay: false,
				},
				emotion: {
					type: "select",
					name: "Emotion",
					value: "Neutral",
					options: ["Happy", "Sad", "Crying", "Neutral", "Delighted", "Angry", "Disgusted", "Afraid"],
					tooltipDisplay: false,
					metersDisplay: false,
				},
				gender: {
					type: "select",
					name: "Gender",
					value: "None",
					options: ["None", "Male", "Female"],
					tooltipDisplay: false,
					metersDisplay: false,
				},
				age: {
					type: "number",
					name: "Age",
					value: 20,
					min: 0,
					max: 100,
					step: 1,
					precision: 0,
					format: "years",
					tooltipDisplay: false,
					metersDisplay: false,
				},
			},
			tooltip: {
				header: "Human",
				body: "A human that can also speak and walk",
			},
			icons: {
				"0_0": humanIcon,
				"0_1": humanSpeaking,
				"1_0": humanMoving,
				"1_1": humanMovingSpeaking,
			},
		},
		qr: {
			icon: qrIcon,
			variables: {
				message: {
					type: "text",
					name: "Message",
					value: "",
					placeholder: "Message",
					tooltipDisplay: true,
				},
			},
			tooltip: {
				header: "QR",
				body: "A QR component with a message",
			},
		},
		poi: {
			icon: poiIcon,
			variables: {},
			icons: {
				default: poiEdge,
			},
			tooltip: {
				header: "POI",
				body: "A point of interest to which items and devices can be moved",
			},
		},
		rfid: {
			icon: rfidIcon,
			variables: {
				message: {
					type: "text",
					name: "Message",
					value: "",
					placeholder: "Message",
					tooltipDisplay: true,
				},
			},
			tooltip: {
				header: "RFID Tag",
				body: "An RFID tag with a message",
			},
		},
		robot: {
			icon: robotNormalRedIcon,
			variables: {
				theta: {
					type: "number",
					name: "Theta",
					value: 0,
					min: -180,
					max: 180,
					step: 10,
					precision: 0,
					format: "°",
					tooltipDisplay: false,
					metersDisplay: false,
				},
				skin: {
					type: "select",
					name: "Skin",
					value: "Normal",
					options: ["Normal", "Scientist", "Formula", "Mini", "Tractor", "Drone", "Roomba"],
					tooltipDisplay: false,
					metersDisplay: false,
				},
				skinColor: {
					type: "select",
					name: "Skin Color",
					value: "Red",
					options: ["Red", "Green", "Blue", "Orange", "Grey"],
					tooltipDisplay: false,
					metersDisplay: false,
				},
				automation: {
					type: "boolean",
					name: "Automated Movement",
					value: false,
					tooltipDisplay: false,
					metersDisplay: false,
				},
				automationPoints: {
					type: "pois",
					name: "Movement Points",
					placeholder: "Add Point",
					value: [],
					tooltipDisplay: false,
					metersDisplay: false,
					needs: { automation: [true] },
				},
				automationReverse: {
					type: "boolean",
					name: "Reverse Movement",
					value: false,
					tooltipDisplay: false,
					metersDisplay: false,
					needs: { automation: [true] },
				},
				automationLoop: {
					type: "boolean",
					name: "Movement Loop",
					value: false,
					tooltipDisplay: false,
					metersDisplay: false,
					needs: { automation: [true] },
				},
				linearSpeed: {
					type: "number",
					name: "Max Linear Speed",
					value: 0.2,
					min: 0,
					max: 10,
					step: 0.1,
					precision: 1,
					format: "m/s",
					tooltipDisplay: false,
					metersDisplay: false,
					needs: { automation: [true] },
				},
				angularSpeed: {
					type: "number",
					name: "Max Angular Speed",
					value: 0.2,
					min: 0,
					max: 10,
					step: 0.1,
					precision: 1,
					format: "rad/s",
					tooltipDisplay: false,
					metersDisplay: false,
					needs: { automation: [true] },
				},
				microphone: {
					type: "boolean",
					name: "Microphone",
					value: false,
					tooltipDisplay: true,
					metersDisplay: false,
					ids: ["microphone_front_"],
					needs: { automation: [false] },
					templateId: "microphone",
					parameters: [["duration"]],
				},
				camera: {
					type: "boolean",
					name: "Camera",
					value: false,
					tooltipDisplay: true,
					metersDisplay: false,
					ids: ["tektrain_camera_"],
					needs: { automation: [false] },
					templateId: "camera",
					parameters: [["-"]],
				},
				cameraOnPanTilt: {
					type: "boolean",
					name: "Camera on Pan-Tilt",
					value: false,
					tooltipDisplay: false,
					metersDisplay: false,
					ids: ["pan_tilt_"],
					parameters: [["pan", "tilt"]],
					needs: { camera: [true], automation: [false] },
				},
				cameraRealImages: {
					type: "boolean",
					name: "Generate Real Images",
					value: false,
					tooltipDisplay: false,
					metersDisplay: false,
					ids: ["tektrain_camera_real_images_"],
					parameters: [["-"]],
					needs: { camera: [true], automation: [false] },
				},
				imu: {
					type: "boolean",
					name: "IMU",
					value: false,
					tooltipDisplay: true,
					metersDisplay: false,
					ids: ["imu_"],
					needs: { automation: [false] },
					templateId: "imu",
					parameters: [["acceleration.x", "acceleration.y", "acceleration.z", "gyroscope.yaw", "gyroscope.pitch", "gyroscope.roll", "magnetometer.yaw", "magnetometer.pitch", "magnetometer.roll"]],
				},
				env: {
					type: "boolean",
					name: "Environmental",
					value: false,
					tooltipDisplay: true,
					metersDisplay: false,
					ids: ["env_"],
					needs: { automation: [false] },
					templateId: "env",
					parameters: [["data.temperature", "data.pressure", "data.humidity", "data.gas"]],
				},
				speaker: {
					type: "boolean",
					name: "Speaker",
					value: false,
					tooltipDisplay: true,
					metersDisplay: false,
					ids: ["speaker_front_"],
					needs: { automation: [false] },
					templateId: "speaker",
					parameters: [["text", "volume", "language"]],
				},
				leds: {
					type: "boolean",
					name: "LEDs",
					value: false,
					tooltipDisplay: true,
					metersDisplay: false,
					ids: ["leds_front_"],
					needs: { automation: [false] },
					templateId: "leds",
					parameters: [["r", "g", "b", "intensity"]],
				},
				panTilt: {
					type: "boolean",
					name: "Pan-Tilt",
					value: false,
					hide: true,
					tooltipDisplay: false,
					metersDisplay: false,
					ids: ["pan_tilt_"],
					needs: { automation: [false] },
					templateId: "pan_tilt",
					parameters: [["pan", "tilt"]],
				},
				rfid_reader: {
					type: "boolean",
					name: "RFID Reader",
					value: false,
					tooltipDisplay: false,
					metersDisplay: false,
					ids: ["rfid_reader_"],
					needs: { automation: [false] },
					templateId: "rfid_reader",
					parameters: [["data.tags.<tag_name>"]],
				},
				rfidOnPanTilt: {
					type: "boolean",
					name: "RFID on Pan-Tilt",
					value: false,
					tooltipDisplay: false,
					metersDisplay: false,
					ids: ["pan_tilt_"],
					parameters: [["pan", "tilt"]],
					needs: { rfid_reader: [true], automation: [false] },
				},
				sonar: {
					type: "extra",
					name: "Sonar",
					needs: { automation: [false] },
					tooltipDisplay: true,
					metersDisplay: false,
					extra: {
						sonar_bl: {
							type: "boolean",
							name: "Back Left",
							value: false,
							tooltipDisplay: true,
							metersDisplay: false,
							ids: ["sonar_bl_"],
							templateId: "sonar_bl",
							parameters: ["distance"],
						},
						sonar_br: {
							type: "boolean",
							name: "Back Right",
							value: false,
							tooltipDisplay: true,
							metersDisplay: false,
							ids: ["sonar_br_"],
							templateId: "sonar_br",
							parameters: ["distance"],
						},
						sonar_fl: {
							type: "boolean",
							name: "Front Left",
							value: false,
							tooltipDisplay: true,
							metersDisplay: false,
							ids: ["sonar_fl_"],
							templateId: "sonar_fl",
							parameters: ["distance"],
						},
						sonar_f: {
							type: "boolean",
							name: "Front",
							value: false,
							tooltipDisplay: true,
							metersDisplay: false,
							ids: ["sonar_f_"],
							templateId: "sonar_f",
							parameters: ["distance"],
						},
						sonar_fr: {
							type: "boolean",
							name: "Front Right",
							value: false,
							tooltipDisplay: true,
							metersDisplay: false,
							ids: ["sonar_fr_"],
							templateId: "sonar_fr",
							parameters: ["distance"],
						},
						sonar_l: {
							type: "boolean",
							name: "Left",
							value: false,
							tooltipDisplay: true,
							metersDisplay: false,
							ids: ["sonar_l_"],
							templateId: "sonar_l",
							parameters: ["distance"],
						},
						sonar_r: {
							type: "boolean",
							name: "Right",
							value: false,
							tooltipDisplay: true,
							metersDisplay: false,
							ids: ["sonar_r_"],
							templateId: "sonar_r",
							parameters: ["distance"],
						},
						sonar_pan_tilt: {
							type: "boolean",
							name: "On Pan-Tilt",
							value: false,
							tooltipDisplay: true,
							metersDisplay: false,
							ids: ["pan_tilt_"],
							templateId: "sonar_c",
							parameters: ["distance"],
						},
					},
				},
			},
			tooltip: {
				header: "Robot",
				body: "A robot is a moving agent that is able to interact with the environment via actuators and measure properties of the environment via sensors",
			},
			icons: {
				Normal: {
					image: {
						Red: robotNormalRedIcon,
						Green: robotNormalGreenIcon,
						Blue: robotNormalBlueIcon,
						Orange: robotNormalOrangeIcon,
						Grey: robotNormalGreyIcon,
					},
					aspectRatio: 1.158,
				},
				Scientist: {
					image: {
						Red: robotScientistRedIcon,
						Green: robotScientistGreenIcon,
						Blue: robotScientistBlueIcon,
						Orange: robotScientistOrangeIcon,
						Grey: robotScientistGreyIcon,
					},
					aspectRatio: 1.771,
				},
				Formula: {
					image: {
						Red: robotFormulaRedIcon,
						Green: robotFormulaGreenIcon,
						Blue: robotFormulaBlueIcon,
						Orange: robotFormulaOrangeIcon,
						Grey: robotFormulaGreyIcon,
					},
					aspectRatio: 1.983,
				},
				Mini: {
					image: {
						Red: robotMiniRedIcon,
						Green: robotMiniGreenIcon,
						Blue: robotMiniBlueIcon,
						Orange: robotMiniOrangeIcon,
						Grey: robotMiniGreyIcon,
					},
					aspectRatio: 1.746,
				},
				Tractor: {
					image: {
						Red: robotTractorRedIcon,
						Green: robotTractorGreenIcon,
						Blue: robotTractorBlueIcon,
						Orange: robotTractorOrangeIcon,
						Grey: robotTractorGreyIcon,
					},
					aspectRatio: 1.65,
				},
				Drone: {
					image: {
						Red: robotDroneRedIcon,
						Green: robotDroneGreenIcon,
						Blue: robotDroneBlueIcon,
						Orange: robotDroneOrangeIcon,
						Grey: robotDroneGreyIcon,
					},
					aspectRatio: 1,
				},
				Roomba: {
					image: {
						Red: robotRoombaRedIcon,
						Green: robotRoombaGreenIcon,
						Blue: robotRoombaBlueIcon,
						Orange: robotRoombaOrangeIcon,
						Grey: robotRoombaGreyIcon,
					},
					aspectRatio: 1.004,
				},
			},
		},
		sound: {
			icon: soundIcon,
			variables: {
				language: {
					type: "select",
					name: "Language",
					value: "EL",
					options: ["EL", "EN"],
					tooltipDisplay: false,
					metersDisplay: false,
				},
				range: {
					type: "number",
					name: "Range",
					value: 20,
					min: 0,
					max: 1000,
					step: 1,
					precision: 1,
					format: "m",
					tooltipDisplay: false,
					metersDisplay: true,
				},
				speech: {
					type: "text",
					name: "Speech",
					value: "",
					placeholder: "Speech",
					tooltipDisplay: false,
					metersDisplay: false,
				},
				emotion: {
					type: "select",
					name: "Emotion",
					value: "Neutral",
					options: ["Happy", "Sad", "Crying", "Neutral", "Delighted", "Angry", "Disgusted", "Afraid"],
					tooltipDisplay: false,
					metersDisplay: false,
				},
			},
			tooltip: {
				header: "Sound",
				body: "A sound source",
			},
		},
		text: {
			icon: textIcon,
			variables: {
				text: {
					type: "text",
					name: "Text",
					value: "",
					placeholder: "Text",
					tooltipDisplay: true,
				},
			},
			tooltip: {
				header: "Text",
				body: "A text that denotes a message and can be detected via OCR algorithms",
			},
		},
		wall: {
			icon: wallIcon,
			itemType: "double",
			variables: {},
			icons: {
				1: wallEdge,
				2: wallEdge,
			},
			tooltip: {
				header: "Wall",
				body: "A wall is an obstacle you can place anywhere in the map in order to not allow the robot to pass through. Walls are detectable from the robot’s distance sensors",
			},
		},
		water: {
			icon: waterIcon,
			variables: {
				range: {
					type: "number",
					name: "Range",
					value: 20,
					min: 0,
					max: 1000,
					step: 1,
					precision: 1,
					format: "m",
					tooltipDisplay: false,
					metersDisplay: true,
				},
			},
			tooltip: {
				header: "Water",
				body: "A source of water, denoting high humidity",
			},
		},
	},
};

export default general;
