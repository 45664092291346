import { Autocomplete, Box, Chip, createFilterOptions, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";

const filter = createFilterOptions();

const useStyles = makeStyles((theme) => ({
	primaryBackground: {
		color: "white!important",
		backgroundColor: theme.palette.primary.main,
		border: `2px solid ${theme.palette.primary.main}`,
		borderRadius: "10px",
		"&:hover": {
			color: "white",
			backgroundColor: theme.palette.primary.dark,
			border: `2px solid ${theme.palette.primary.dark}`,
		},
		"&:disabled": {
			color: "white",
			backgroundColor: theme.palette.grey.main,
			border: `2px solid ${theme.palette.grey.main}`,
		},
	},
	primaryBorder: {
		color: "black",
		backgroundColor: "#abc7d9",
		// border: `2px solid ${theme.palette.primary.main}`,
		borderRadius: "2px",
		"&:hover": {
			color: theme.palette.primary.main,
			backgroundColor: theme.palette.grey.light,
			// border: `2px solid ${theme.palette.primary.main}`,
		},
		"&:disabled": {
			color: "white",
			backgroundColor: theme.palette.grey.main,
			border: `2px solid ${theme.palette.grey.main}`,
		},
	},
	whiteIcon: {
		color: "white",
	},
	primaryIcon: {
		color: theme.palette.primary.main,
	},
}));

export const PrimaryBackgroundAutocomplete = ({
	id = "primary-background-autocomplete",
	disabled = false,
	className = "",
	label = "Label",
	placeholder = "Placeholder",
	width = "200px",
	height = "40px",
	fontSize = "14px",
	options = [],
	allowCustomInput = true,
	value = "",
	onChange = () => {},
}) => {
	const classes = useStyles();

	return (
		<Autocomplete
			key={id}
			id={id}
			value={value}
			disabled={disabled}
			placeholder={placeholder}
			className={`${className} ${classes.primaryBackground}`}
			style={{ ...(width && { width }), ...(height && { height }) }}
			options={options}
			renderOption={(props, option) => {
				const { key, ...optionProps } = props;
				return (
					<Box
						key={key}
						component="li"
						sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
						{...optionProps}
					>
						{option}
					</Box>
				);
			}}
			renderInput={(params) => (<TextField {...params} label={label} />)}
			filterOptions={(fOptions, params) => {
				const filtered = filter(fOptions, params);
				if (params.inputValue !== "" && allowCustomInput) {
					filtered.push(params.inputValue);
				}

				return filtered;
			}}
			sx={{
				...(width && { width }),
				fontSize,
				"& .MuiInputBase-root": {
					height,
				},
				"& .MuiInputLabel-root": {
					transform: `translate(14px, ${value ? "-9" : "11"}px) scale(${value ? "0.75" : "1"})`,
				},
				"& .MuiInputLabel-root.Mui-focused": {
					transform: "translate(14px, -9px) scale(0.75)",
				},
			}}
			onChange={(_, newValue) => onChange(newValue)}
		/>
	);
};

export const PrimaryBorderAutocomplete = ({
	id = "primary-border-autocomplete",
	disabled = false,
	className = "",
	label = "Label",
	placeholder = "Placeholder",
	width = "200px",
	height = "40px",
	fontSize = "14px",
	options = [],
	allowCustomInput = true,
	value = "",
	onChange = () => {},
}) => {
	const classes = useStyles();

	return (
		<Autocomplete
			key={id}
			id={id}
			value={value}
			disabled={disabled}
			placeholder={placeholder}
			className={`${className} ${classes.primaryBorder}`}
			style={{ ...(width && { width }), ...(height && { height }) }}
			options={options}
			renderOption={(props, option) => {
				const { key, ...optionProps } = props;
				return (
					<Box
						key={key}
						component="li"
						sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
						{...optionProps}
					>
						{option}
					</Box>
				);
			}}
			renderInput={(params) => (
				<TextField
					{...params}
					label={value ? "" : label}
					InputLabelProps={{
						shrink: false,
					}}
				/>
			)}
			filterOptions={(fOptions, params) => {
				const filtered = filter(fOptions, params);
				if (params.inputValue !== "" && allowCustomInput) {
					filtered.push(params.inputValue);
				}

				return filtered;
			}}
			sx={{
				...(width && { width }),
				fontSize,
				"& .MuiInputBase-root": {
					height,
				},
				"& .MuiInputLabel-root": {
					transform: `translate(14px, ${value ? "-9" : "11"}px) scale(${value ? "0.75" : "1"})`,
					color: "grey !important",
				},
				"& .MuiInputLabel-root.Mui-focused": {
					transform: "translate(14px, -9px) scale(0.75)",
				},
			}}
			onChange={(_, newValue) => onChange(newValue)}
		/>
	);
};

export const PrimaryBorderTagInput = ({
	id = "primary-border-tag-input",
	disabled = false,
	className = "",
	placeholder = "Placeholder",
	width = "200px",
	fontSize = "14px",
	value = [],
	onChange = () => {},
}) => {
	const classes = useStyles();

	return (
		<Autocomplete
			key={id}
			freeSolo
			multiple
			disableClearable
			id={id}
			value={value}
			disabled={disabled}
			placeholder={placeholder}
			className={`${className} ${classes.primaryBorder}`}
			style={{ ...(width && { width }) }}
			options={[]}
			renderInput={(params) => (
				<TextField
					{...params}
					// label={label}
					InputLabelProps={{
						shrink: false,
					}}
				/>
			)}
			renderTags={(tagsValue, props) => tagsValue.map((option, index) => (
				<Chip
					key={index}
					label={option}
					{...props({ index })}
					disabled={tagsValue.length === 1}
				/>
			))}
			sx={{
				...(width && { width }),
				fontSize,
			}}
			onChange={(_, newValues) => onChange(newValues)}
		/>
	);
};
