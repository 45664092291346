import cashier from "../../../assets/images/background/super_market/cashier.png";
import cheese from "../../../assets/images/background/super_market/cheese.png";
import fish from "../../../assets/images/background/super_market/fish.png";
import floor from "../../../assets/images/background/super_market/floor.png";
import floorCheese from "../../../assets/images/background/super_market/floor_cheese.png";
import floorCorner from "../../../assets/images/background/super_market/floor_corner.png";
import floorCross from "../../../assets/images/background/super_market/floor_cross.png";
import floorHall from "../../../assets/images/background/super_market/floor_hall.png";
import floorStraight from "../../../assets/images/background/super_market/floor_straight.png";
import floorT from "../../../assets/images/background/super_market/floor_t.png";
import floorUp from "../../../assets/images/background/super_market/floor_up.png";
import floorUpLeft from "../../../assets/images/background/super_market/floor_up_left.png";
import floorVegetables from "../../../assets/images/background/super_market/floor_vegetables.png";
import frozenPies from "../../../assets/images/background/super_market/frozen_pies.png";
import grocery from "../../../assets/images/background/super_market/grocery.png";
import meat from "../../../assets/images/background/super_market/meat.png";
import office from "../../../assets/images/background/super_market/office.png";
import start from "../../../assets/images/background/super_market/start.png";

const superMarket = {
	name: "Super Market",
	components: {
		cashier: {
			id: "cashier",
			image: cashier,
			text: "Cashier",
			pois: { name: "", items: [] },
			walls: [
				{ x1: 0, y1: 36, x2: 100, y2: 36 },
				{ x1: 0, y1: 64, x2: 100, y2: 64 },
			],
			waters: [],
		},
		cheese: {
			id: "cheese",
			image: cheese,
			text: "Cheese",
			pois: { name: "", items: [] },
			walls: [
				{ x1: 36, y1: 41, x2: 36, y2: 0 },
				{ x1: 36, y1: 0, x2: 0, y2: 0 },
				{ x1: 0, y1: 0, x2: 0, y2: 100 },
				{ x1: 0, y1: 100, x2: 100, y2: 100 },
				{ x1: 100, y1: 100, x2: 100, y2: 0 },
				{ x1: 100, y1: 0, x2: 64, y2: 0 },
				{ x1: 64, y1: 0, x2: 64, y2: 41 },
			],
			waters: [],
		},
		fish: {
			id: "fish",
			image: fish,
			text: "Fish",
			pois: { name: "", items: [] },
			walls: [
				{ x1: 36, y1: 41, x2: 36, y2: 0 },
				{ x1: 36, y1: 0, x2: 0, y2: 0 },
				{ x1: 0, y1: 0, x2: 0, y2: 100 },
				{ x1: 0, y1: 100, x2: 100, y2: 100 },
				{ x1: 100, y1: 100, x2: 100, y2: 0 },
				{ x1: 100, y1: 0, x2: 64, y2: 0 },
				{ x1: 64, y1: 0, x2: 64, y2: 41 },
			],
			waters: [],
		},
		floor: {
			id: "floor",
			image: floor,
			text: "Floor",
			pois: { name: "", items: [] },
			walls: [],
			waters: [],
		},
		floorCheese: {
			id: "floorCheese",
			image: floorCheese,
			text: "Floor Cheese",
			pois: { name: "", items: [] },
			walls: [
				{ x1: 4, y1: 37, x2: 4, y2: 63 },
				{ x1: 4, y1: 63, x2: 96, y2: 63 },
				{ x1: 96, y1: 63, x2: 96, y2: 37 },
				{ x1: 96, y1: 37, x2: 4, y2: 37 },
			],
			waters: [],
		},
		floor_corner: {
			id: "floor_corner",
			image: floorCorner,
			text: "Floor Corner",
			pois: { name: "", items: [] },
			walls: [
				{ x1: 0, y1: 0, x2: 0, y2: 100 },
				{ x1: 0, y1: 100, x2: 100, y2: 100 },
				{ x1: 100, y1: 100, x2: 100, y2: 64 },
				{ x1: 100, y1: 64, x2: 36, y2: 64 },
				{ x1: 36, y1: 64, x2: 36, y2: 0 },
				{ x1: 36, y1: 0, x2: 0, y2: 0 },
				{ x1: 100, y1: 36, x2: 64, y2: 36 },
				{ x1: 64, y1: 36, x2: 64, y2: 0 },
			],
			waters: [],
		},
		floorCross: {
			id: "floorCross",
			image: floorCross,
			text: "Floor Cross",
			pois: { name: "", items: [] },
			walls: [
				{ x1: 0, y1: 36, x2: 36, y2: 36 },
				{ x1: 36, y1: 36, x2: 36, y2: 0 },
				{ x1: 0, y1: 64, x2: 36, y2: 64 },
				{ x1: 36, y1: 64, x2: 36, y2: 100 },
				{ x1: 64, y1: 100, x2: 64, y2: 64 },
				{ x1: 64, y1: 64, x2: 100, y2: 64 },
				{ x1: 100, y1: 36, x2: 64, y2: 36 },
				{ x1: 64, y1: 36, x2: 64, y2: 0 },
			],
			waters: [],
		},
		floorHall: {
			id: "floorHall",
			image: floorHall,
			text: "Floor Hall",
			pois: { name: "", items: [] },
			walls: [
				{ x1: 36, y1: 0, x2: 36, y2: 36 },
				{ x1: 36, y1: 36, x2: 0, y2: 36 },
				{ x1: 0, y1: 64, x2: 36, y2: 64 },
				{ x1: 36, y1: 64, x2: 36, y2: 100 },
			],
			waters: [],
		},
		floor_straight: {
			id: "floor_straight",
			image: floorStraight,
			text: "Floor Straight",
			pois: { name: "", items: [] },
			walls: [
				{ x1: 0, y1: 36, x2: 100, y2: 36 },
				{ x1: 0, y1: 64, x2: 100, y2: 64 },
			],
			waters: [],
		},
		floor_t: {
			id: "floor_t",
			image: floorT,
			text: "Floor T",
			pois: { name: "", items: [] },
			walls: [
				{ x1: 0, y1: 36, x2: 36, y2: 36 },
				{ x1: 36, y1: 36, x2: 36, y2: 0 },
				{ x1: 0, y1: 64, x2: 100, y2: 64 },
				{ x1: 100, y1: 36, x2: 64, y2: 36 },
				{ x1: 64, y1: 36, x2: 64, y2: 0 },
			],
			waters: [],
		},
		floorUp: {
			id: "floorUp",
			image: floorUp,
			text: "Floor Up",
			pois: { name: "", items: [] },
			walls: [{ x1: 0, y1: 64, x2: 100, y2: 64 }],
			waters: [],
		},
		floorUpLeft: {
			id: "floorUpLeft",
			image: floorUpLeft,
			text: "Floor Up Left",
			pois: { name: "", items: [] },
			walls: [
				{ x1: 36, y1: 0, x2: 36, y2: 64 },
				{ x1: 36, y1: 64, x2: 100, y2: 64 },
			],
			waters: [],
		},
		floorVegetables: {
			id: "floorVegetables",
			image: floorVegetables,
			text: "Floor Vegetables",
			pois: { name: "", items: [] },
			walls: [
				{ x1: 50, y1: 82, x2: 71, y2: 72 },
				{ x1: 71, y1: 72, x2: 79, y2: 53 },
				{ x1: 79, y1: 53, x2: 72, y2: 34 },
				{ x1: 72, y1: 34, x2: 50, y2: 24 },
				{ x1: 50, y1: 24, x2: 28, y2: 34 },
				{ x1: 28, y1: 34, x2: 21, y2: 53 },
				{ x1: 21, y1: 53, x2: 28, y2: 72 },
				{ x1: 28, y1: 72, x2: 50, y2: 82 },
			],
			waters: [],
		},
		frozen_pies: {
			id: "frozen_pies",
			image: frozenPies,
			text: "Frozen Pies",
			pois: { name: "", items: [] },
			walls: [
				{ x1: 0, y1: 36, x2: 100, y2: 36 },
				{ x1: 0, y1: 64, x2: 100, y2: 64 },
			],
			waters: [],
		},
		grocery: {
			id: "grocery",
			image: grocery,
			text: "Grocery",
			pois: { name: "", items: [] },
			walls: [
				{ x1: 0, y1: 36, x2: 100, y2: 36 },
				{ x1: 0, y1: 64, x2: 100, y2: 64 },
			],
			waters: [],
		},
		meat: {
			id: "meat",
			image: meat,
			text: "Meat",
			pois: { name: "", items: [] },
			walls: [
				{ x1: 36, y1: 41, x2: 36, y2: 0 },
				{ x1: 36, y1: 0, x2: 0, y2: 0 },
				{ x1: 0, y1: 0, x2: 0, y2: 100 },
				{ x1: 0, y1: 100, x2: 100, y2: 100 },
				{ x1: 100, y1: 100, x2: 100, y2: 0 },
				{ x1: 100, y1: 0, x2: 64, y2: 0 },
				{ x1: 64, y1: 0, x2: 64, y2: 41 },
			],
			waters: [],
		},
		office: {
			id: "office",
			image: office,
			text: "Office",
			pois: { name: "", items: [] },
			walls: [
				{ x1: 36, y1: 16, x2: 36, y2: 0 },
				{ x1: 36, y1: 0, x2: 0, y2: 0 },
				{ x1: 0, y1: 0, x2: 0, y2: 100 },
				{ x1: 0, y1: 100, x2: 100, y2: 100 },
				{ x1: 100, y1: 100, x2: 100, y2: 0 },
				{ x1: 100, y1: 0, x2: 64, y2: 0 },
				{ x1: 64, y1: 0, x2: 64, y2: 16 },
			],
			waters: [],
		},
		start: {
			id: "start",
			image: start,
			text: "Start",
			pois: { name: "", items: [] },
			walls: [
				{ x1: 0, y1: 0, x2: 0, y2: 100 },
				{ x1: 0, y1: 100, x2: 36, y2: 100 },
				{ x1: 36, y1: 100, x2: 25, y2: 0 },
				{ x1: 25, y1: 0, x2: 0, y2: 0 },
				{ x1: 73, y1: 100, x2: 100, y2: 100 },
				{ x1: 100, y1: 100, x2: 100, y2: 0 },
				{ x1: 100, y1: 0, x2: 75, y2: 0 },
				{ x1: 75, y1: 0, x2: 73, y2: 100 },
			],
			waters: [],
		},
	},
};

export default superMarket;
