import { memo, useCallback, useState, useMemo, useRef, useEffect } from "react";
import ReactJson from 'react-json-view';
import { useSelector, useDispatch } from 'react-redux';
import { setBrokers } from "../components/AppCreator/slice-brokers.js";
import { addThread, deleteThread, setThreads } from "../components/AppCreator/slice-threads.js";
import { addNodeParameters, deleteNodeParameters, setNodesParameters } from "../components/AppCreator/slice-nodes.js";
import { addLog, cleanLogs } from "../components/AppCreator/slice-logs.js";

import {
	Chip,
	Divider,
	Grid,
	Typography,
	Select,
	MenuItem,
	IconButton,
	Switch,
} from "@mui/material";
import { Delete, ZoomInMap, ZoomOutMap } from "@mui/icons-material";

import ReactFlow, {
	Controls,
	Background,
	useNodesState,
	useEdgesState,
	addEdge,
	applyEdgeChanges,
	applyNodeChanges,
	ReactFlowProvider,
	MiniMap,
} from 'reactflow';

import 'reactflow/dist/style.css';

import Spinner from "../components/Spinner.js";
// import { useSnackbar } from "../utils/index.js";

import { systemToolboxes } from "../utils/system-toolboxes.js";
import CustomNode from "../components/AppCreator/node-custom.js";
import ThreadNode from "../components/AppCreator/node-thread.js";
import ConditionNode from "../components/AppCreator/node-condition.js";
import RandomNode from "../components/AppCreator/node-random.js";
import { connect, disconnect } from "../utils/websocket.js";
import { getModel } from "../api/index.js";
import CreateMission from "../components/create-mission-2/index.js";

const PROXIMITY_THRESHOLD = 200;
const GHOST_EDGE_COLOR = "rgba(200, 200, 200, 0.5)";

const nodeTypes = {
	custom: CustomNode,
	thread: ThreadNode,
	condition: ConditionNode,
	random: RandomNode,
};

const evaluatePath = (obj, path) => path.split('.').reduce((acc, key) => acc[key], obj);

const onDragStart = (event, nodeType) => {
	event.dataTransfer.setData('application/reactflow', JSON.stringify(nodeType));
	event.dataTransfer.effectAllowed = 'move';
};

const fixNodesValues = (_nodes, _store) => {
	const nds = _nodes.map((node) => {
		const foundNode = _store.storeNodes.find((n) => n.id === node.id);
		if (foundNode) {
			node.data = {
				...node.data,
				parameters: "parameters" in foundNode?.parameters
					? foundNode?.parameters?.parameters
					: foundNode?.parameters,
			};

			if ("inputs" in foundNode.parameters) {
				node.data.inputs = foundNode.parameters.inputs;
			}

			if ("outputs" in foundNode.parameters) {
				node.data.outputs = foundNode.parameters.outputs;
			}
		}

		return node;
	});
	return nds;
};

const sortLogs = (a, b) => {
	const aTs = new Date(a.payload.timestamp);
	const bTs = new Date(b.payload.timestamp);
	return bTs - aTs;
};

const Testbed = (props) => {
	// const { error, success } = useSnackbar();
	const isLoading = useMemo(() => false, []);
	const [nodes, setNodes] = useNodesState([]);
	const [edges, setEdges] = useEdgesState([]);
	const [ghostEdge, setGhostEdge] = useState(null);

	const [variables, setVariables] = useState([]);
	const [toolboxVariables, setToolboxVariables] = useState([]);
	const [customVariables, setCustomVariables] = useState([]);
	const [liveVariables, setLiveVariables] = useState({});

	// const { setViewport } = useReactFlow();
	const reactFlowWrapper = useRef(null);
	const [reactFlowInstance, setReactFlowInstance] = useState(null);
	const [toolboxes, setToolboxes] = useState(systemToolboxes);
	// const [modelToolboxes, setModelToolboxes] = useState([]);
	const [counter, setCounter] = useState(0);
	const [hasChanged, setHasChanged] = useState(false);
	const [autoSaveTimerId, setAutoSaveTimerId] = useState(null);
	const modelUpdate = props.modelUpdate;
	const dispatch = useDispatch();

	// Get all variables from redux
	const storeThreads = useSelector((state) => state.threads);
	const storeBrokers = useSelector((state) => state.brokers);
	const storeNodes = useSelector((state) => state.nodes);
	const storeLogs = useSelector((state) => state.logs);
	const [isSelectionChange, setIsSelectionChange] = useState(false);

	const [initialModelLoaded, setInitialModelLoaded] = useState(false);

	const prevModel = useRef(props.model);

	const [programRunning, setProgramRunning] = useState(false);
	const [lastExecutionTimestamp, setLastExecutionTimestamp] = useState(0);
	const [runningNodes, setRunningNodes] = useState([]);
	const [selectedNode, setSelectedNode] = useState(null);
	const [highlightedEdges, setHighlightedEdges] = useState([]);
	const [initialLoad, setInitialLoad] = useState(true);
	const [edgeStyle, setEdgeStyle] = useState("default");
	const [validationErrors, setValidationErrors] = useState([]);
	const [isFromValidation, setIsFromValidation] = useState(false);
	const [currentConnection, setCurrentConnection] = useState(null);
	const [isProximityEnabled, setIsProximityEnabled] = useState(true);

	const [envpopModel, setEnvpopModel] = useState(null);
	const [envpopSize, setEnvpopSize] = useState("small");
	const envpopRef = useRef(null);
	const [inSimulation, setInSimulation] = useState(props.inSimulation);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const updateModel = useCallback(() => {
		if (reactFlowInstance === null || programRunning) {
			return;
		}

		const toStore = reactFlowInstance.toObject();
		// Exclude className from nodes and edges
		const nodesWithoutClassName = toStore.nodes.map(({ className, selected, data, ...node }) => {
			const { error, ...filteredData } = data || {}; // Remove the error from data
			return { ...node, data: filteredData };
		});
		const edgesWithoutClassName = toStore.edges.map(({ className, selected, ...edge }) => edge);

		toStore.nodes = nodesWithoutClassName;
		toStore.edges = edgesWithoutClassName;
		toStore.toolboxes = toolboxes;
		toStore.store = {
			storeVariables: variables,
			storeThreads,
			storeBrokers,
			storeNodes,
		};

		if (!initialLoad && !isFromValidation) {
			console.info("[Testbed] Saving model to DB");
			modelUpdate(JSON.stringify(toStore));
		}

		setHasChanged(false);
		setInitialLoad(false);
		setIsFromValidation(false);
	});

	useEffect(() => {
		// console.log(">> programRunning changed!", programRunning);
		setNodes((nds) => nds.map((node) => {
			node.style = {
				...node.style,
				opacity: programRunning ? 0.3 : 1,
			};
			return node;
		}));
	}, [programRunning]);

	useEffect(() => {
		// console.log(">> runningNodes changed!", runningNodes);
		setNodes((nds) => nds.map((node) => {
			node.style = {
				...node.style,
				opacity: programRunning && runningNodes.includes(node.id) ? 1 : (programRunning ? 0.3 : 1),
			};
			return node;
		}));
	}, [runningNodes]);

	useEffect(() => {
		if (!initialLoad) {
			if (selectedNode === null && highlightedEdges.length === 0) {
				setNodes((nds) => nds.map((node) => ({
					...node,
					className: '',
				})));

				setEdges((eds) => eds.map((edge) => ({
					...edge,
					className: '',
				})));
			} else {
				setNodes((nds) => nds.map((node) => ({
					...node,
					className: node.id === selectedNode ? 'selected' : '',
				})));

				setEdges((eds) => eds.map((edge) => ({
					...edge,
					className: highlightedEdges.includes(edge.id) ? 'selected' : '',
				})));
			}
		}
	}, [selectedNode, highlightedEdges]);

	useEffect(() => {
		if (!programRunning) {
			setNodes((currentNodes) => fixNodesValues(currentNodes, { storeNodes }));
		}
	}, [storeNodes, programRunning]);

	useEffect(() => {
		if (programRunning) {
			return;
		}

		const _tmpVars = [];
		for (const node of nodes) {
			if (node.data.label === "Create variable") {
				_tmpVars.push({
					nodeId: node.id,
					variable: node.data.parameters[0].value,
					data: node,
					count: counter + 1,
					value: node.data.parameters[1].value,
					literalVariables: [node.data.parameters[0].value],
				});
			}
		}

		const parsedlist = [];
		for (const v of _tmpVars) {
			console.log("--> Variable", v);
			const _n = v.variable ?? "undefined";
			const _v = v.value ?? "undefined";

			const literals = v.literalVariables;

			for (const l of literals) {
				parsedlist.push(l);
			}
		}

		setVariables(_tmpVars);
		setCustomVariables(parsedlist);

		console.log(">>> Updated CUSTOM variables based on nodes", _tmpVars);
	}, [nodes]);

	// These are only for the initial load
	const updateToolboxVariables = (_toolboxes) => {
		// Check subscribers to add variables
		const tvars = [];
		for (const toolbox of _toolboxes) {
			for (const node of toolbox.nodes) {
				// console.log("Checking node", node);
				if (node.action && (node.action.type === "subscribe" || node.action.type === "rpc") && node.action.storage) {
					// check if variable already exists in variables
					tvars.push({
						nodeId: node.id,
						variable: node.action.storage,
						data: node,
						count: counter + 1,
						value: undefined,
						literalVariables: node.literalVariables,
					});
				}
			}
		}

		const parsedToolboxVariables = [];
		for (const v of tvars) {
			const _n = v.variable ?? "undefined";
			const _v = v.value ?? "undefined";

			const literals = v.literalVariables;

			for (const l of literals) {
				parsedToolboxVariables.push(l);
			}
		}

		setToolboxVariables(parsedToolboxVariables);
		console.log(">>> Updated TOOLBOX variables based on nodes", tvars);
	};

	// Used to load the stored model from the props
	useEffect(() => {
		setValidationErrors(props?.validationErrors);
		// Second time, where the model is properly fetched
		if (prevModel.current !== props.model && prevModel.current === "" && props.model !== "") {
			const flow = JSON.parse(props.model);
			console.log("Loading model from DB", flow);
			if (flow) {
				const alreadyExistingToolboxes = flow?.toolboxes.map((tlb) => tlb?.name);

				const newToolboxes = [];

				for (const systemToolbox of systemToolboxes) {
					if (!alreadyExistingToolboxes.includes(systemToolbox?.name)) {
						newToolboxes.push(systemToolbox);
					}
				}

				if (newToolboxes.length > 0) {
					setToolboxes([...newToolboxes, ...flow?.toolboxes]);
				} else {
					setToolboxes(flow?.toolboxes);
				}

				// Update all nodes with the respective parameters in the flow.store.storeNodes
				const nds = fixNodesValues(flow.nodes, flow.store);
				// console.log("Nodes from db, after updating the parameters", nds);
				flow.nodes = nds;
				setNodes(flow.nodes || []);
				setEdges(flow.edges || []);
				// Give the max counter value of nodes plus one to the counter
				for (const node of flow.nodes) {
					if (node.data.count > counter) {
						setCounter(node.data.count);
					}
				}

				// If the model is created directly from appcreator it does not have toolboxes
				if (flow?.toolboxes) {
					updateToolboxVariables(flow.toolboxes);
				}

				dispatch(setThreads(flow.store.storeThreads || []));
				dispatch(setBrokers(flow.store.storeBrokers || []));
				dispatch(setNodesParameters(flow.store.storeNodes || []));
				setInitialModelLoaded(true);

				if (flow?.edges && flow?.edges.length > 0) {
					setEdgeStyle(flow.edges[0].type);
				} else {
					setEdgeStyle("default");
				}
			}

			prevModel.current = props.model;
			setProgramRunning(false);
			setLastExecutionTimestamp(Date.now());
		}
	}, [props]);

	useEffect(() => {
		if (reactFlowInstance && initialModelLoaded && nodes?.length > 1) {
			reactFlowInstance.fitView();
		}
	}, [reactFlowInstance, initialModelLoaded]);

	const updateVariableValue = (payload) => {
		const variable = payload.key;
		const value = payload.value;
		const allVariables = [...toolboxVariables, ...customVariables];

		const flattenedValues = flattenObject(value, variable);
		const filteredValues = Object.fromEntries(
			Object.entries(flattenedValues).filter(([key]) => allVariables.includes(key))
		);

		const nodesString = JSON.stringify(nodes);

		const finalValues = Object.fromEntries(
			Object.entries(filteredValues).filter(([key]) => nodesString.includes(key))
		);

		if (Object.keys(finalValues).length > 0) {
			setLiveVariables((prevVars) => ({
				...prevVars,
				...finalValues,
			}));
		}
	};

	const flattenObject = (obj, parentKey = "") => {
		const result = {};

		if (typeof obj === "object" && obj !== null) {
			for (const key in obj) {
				if (obj.hasOwnProperty(key)) {
					const newKey = parentKey ? `${parentKey}.${key}` : key;
					const value = obj[key];

					if (typeof obj === "object" && value !== null) {
						Object.assign(result, flattenObject(value, newKey));
					} else {
						result[newKey] = value;
					}
				}
			}
		} else {
			result[parentKey] = obj;
		}

		return result;
	};

	useEffect(() => {
		console.log("Custom variables changed", customVariables);
	}, [customVariables]);

	useEffect(() => {
		console.log("Toolbox variables changed", toolboxVariables);
	}, [toolboxVariables]);

	useEffect(() => {
		(async () => {
			if (currentConnection) {
				await disconnect({ connection: currentConnection });
				console.log("Disconnected from WebSocket");
			}

			const conn = await connect({
				onConnect: () => console.log("WebSocket connected"),
				onError: () => { },
				onMessage: (msg) => {
					const payload = JSON.parse(msg);
					if (payload.modelId !== props.modelid) {
						return;
					}

					// console.log("Payload received", payload);
					if (payload.label === "Log" && (payload.message !== "start" && payload.message !== "end")) {
						// console.log("Log received", payload.message);
						dispatch(addLog({
							payload: payload.message,
						}));
					}

					if ("program" in payload) {
						console.log("Program received", payload.program);
						// Make the background of all nodes white
						setProgramRunning(payload.program === "start");

						if (payload.program === "start") {
							dispatch(cleanLogs());
							setLiveVariables({});
						} else {
							const ts = Date.now();
							setLastExecutionTimestamp(ts);
							console.log("Program ended on", new Date(ts).toLocaleString());
						}
					}

					if ("node_id" in payload) {
						// console.log("Node id", payload.message, payload.node_id);
						if (payload.message === "start") {
							// Add the id in the running nodes
							setRunningNodes((prevNodes) => {
								if (prevNodes.includes(payload.node_id)) {
									return prevNodes;
								}

								return [...prevNodes, payload.node_id];
							});
						} else {
							// Remove the id from the running nodes
							setRunningNodes((prevNodes) => prevNodes.filter((id) => id !== payload.node_id));
						}
					}

					if ("type" in payload && payload.type === "storage") {
						console.log("Storage received", payload);
						updateVariableValue(payload);
					}
				},
				type: "appcreator",
			});

			setCurrentConnection(conn);

			return async () => {
				if (conn) {
					await disconnect({ connection: conn });
				}
			};
		})();
	}, [programRunning]);

	useEffect(() => {
		const timeFromLastExecution = Date.now() - lastExecutionTimestamp;
		if (programRunning || timeFromLastExecution < 500 || isSelectionChange) {
			setIsSelectionChange(false);
			return;
		}

		setHasChanged(true);
		console.log("[Testbed] Something changed in model");
	}, [storeThreads, storeBrokers, storeNodes, nodes, edges, initialModelLoaded]);

	useEffect(() => {
		// stop previous save timer if exists
		if (autoSaveTimerId) {
			clearTimeout(autoSaveTimerId);
		}

		// set new auto save timer
		const newTimeout = setTimeout(updateModel, 1000);
		setHasChanged(false);
		setAutoSaveTimerId(newTimeout);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hasChanged]);

	const onConnect = useCallback(
		async (params) => {
			const edgeExists = edges.some(
				(edge) => edge.source === params.source && edge.target === params.target
			);

			if (edgeExists) {
				return;
			}

			params.type = edgeStyle;
			await setEdges((eds) => addEdge(params, eds));
		},
		[setEdges, edgeStyle, edges]
	);

	const onNodesChange = useCallback(
		async (changes) => {
			await setNodes((nds) => applyNodeChanges(changes, nds));
		},
		[setNodes],
	);
	const onEdgesChange = useCallback(
		async (changes) => {
			console.log("In onEdgesChange");
			await setEdges((eds) => applyEdgeChanges(changes, eds));
		},
		[setEdges],
	);

	const onNodeDrag = useCallback(
		(_, draggedNode) => {
			if (!isProximityEnabled) return;
			const { id: draggedNodeId, position } = draggedNode;

			let closestNode = null;
			let minDistance = PROXIMITY_THRESHOLD;

			nodes.forEach((node) => {
				if (node.id !== draggedNodeId) {
					const dx = node.position.x - position.x;
					const dy = node.position.y - position.y;
					const distance = Math.sqrt(dx * dx + dy * dy);

					if (distance < minDistance) {
						minDistance = distance;
						closestNode = node;
					}
				}
			});

			if (closestNode) {
				const edgeExists = edges.some(
					(edge) =>
						(edge.source === draggedNodeId && edge.target === closestNode.id) ||
						(edge.source === closestNode.id && edge.target === draggedNodeId)
				);

				console.log("EDGE EXISTS", edgeExists)
				if (!edgeExists) {
					const closeNodeIsSource = closestNode.position.x < draggedNode.position.x;

					setGhostEdge({
						id: `ghost-${draggedNodeId}-${closestNode.id}`,
						source: closeNodeIsSource ? closestNode.id : draggedNodeId,
						target: closeNodeIsSource ? draggedNodeId : closestNode.id,
						type: edgeStyle,
						animated: true,
						style: { stroke: GHOST_EDGE_COLOR, strokeWidth: 2 },
					});
				} else {
					setGhostEdge(null);
				}
			} else {
				setGhostEdge(null);
			}
		}, [nodes, edges, edgeStyle, isProximityEnabled]);





	const onNodeDragStop = useCallback(
		() => {
			if (ghostEdge) {
				setEdges((eds) =>
					addEdge(
						{ ...ghostEdge, animated: false, type: edgeStyle },
						eds
					)
				);
			}
			setGhostEdge(null);
		},
		[ghostEdge, setEdges, edgeStyle]
	);

	const applyErrorsToNodes = (nodes, errors) => {
		if (errors) {
			return nodes.map((node) => {
				const nodeErrors = errors?.payload?.filter((error) => error.id === node.data.count);

				return {
					...node,
					data: {
						...node.data,
						error: nodeErrors?.length > 0 ? nodeErrors.map((err) => err.message) : [],
					},
					className: nodeErrors?.length > 0 ? 'node-error' : '',
				};
			});
		}

		return nodes;
	};

	// Example usage in state update
	useEffect(() => {
		console.log("Validation errors changed", validationErrors);
		setIsFromValidation(true);
		setNodes((prevNodes) => applyErrorsToNodes(prevNodes, validationErrors));
	}, [validationErrors]);

	useEffect(() => {
		(async () => {
			if (props?.dbitem?.origin && props.dbitem.origin !== "") {
				const { success: scs, model: mo } = await getModel(props.dbitem.origin);
				if (scs) {
					setEnvpopModel(mo);
				}
			}
		})();
	}, [props.dbitem, props.model]);

	const onDragOver = useCallback((event) => {
		event.preventDefault();
		event.dataTransfer.dropEffect = 'move';
	}, []);

	const onNodesDelete = useCallback(
		async (nodesToDelete) => {
			// console.log("In onNodesDelete");
			// console.log("Nodes to delete", nodesToDelete);
			// Remove the node from the storageVariables
			for (const node of nodesToDelete) {
				// NOTE: Commented this since the variables handling will change
				console.log("Removing variable for node", node, variables);
				setVariables(variables.filter((v) => v.nodeId !== node));

				// console.log("Removing thread for node", node);
				dispatch(deleteThread({
					id: node.id,
				}));

				// console.log("Removing node", node);
				dispatch(deleteNodeParameters({
					id: node.id,
				}));
			}

			await setNodes((nds) => nds.filter((n) => !nodesToDelete.includes(n.id)));
			await setEdges((eds) => eds.filter((e) => !nodesToDelete.includes(e.source) && !nodesToDelete.includes(e.target)));
			// updateModel();
		},
		[dispatch],
	);

	const onDrop = useCallback(
		async (event) => {
			// console.log("In onDrop");
			event.preventDefault();

			const node = JSON.parse(event.dataTransfer.getData('application/reactflow'));

			// check if the dropped element is valid
			if (node.type === undefined || !node.type) {
				node.type = "default";
			}

			const position = reactFlowInstance.screenToFlowPosition({
				x: event.clientX,
				y: event.clientY,
			});

			// Types mapping
			const typesMapping = {
				"Thread split": "thread",
				"Thread join": "thread",
				Condition: "condition",
				Random: "random",
			};

			const id = Math.random().toString();
			const newNode = {
				id,
				type: typesMapping[node.name] ?? "custom",
				sourcePosition: 'right',
				targetPosition: 'left',
				position,
				data: {
					id,
					label: node.name,
					inputs: node.inputs,
					outputs: node.outputs,
					backgroundColor: node.backgroundColor,
					toolboxColor: node.toolboxColor,
					fontColor: node.fontColor,
					toolbox: node.toolbox,
					parameters: node.parameters,
					action: node.action,
					count: counter + 1,
				},
			};
			if (node.name === "Create variable") {
				newNode.data.parameters[0].value = `variable_${counter + 1}`;
			}

			console.log("Node to update the store", node);

			// This happens for nodes with variable inputs/outputs but no parameters
			const toDispatch = {
				id,
				parameters: node.parameters ?? [],
			};
			if (newNode.data.label === "Thread split" || newNode.data.label === "Thread join" || newNode.data.label === "Random") {
				toDispatch.parameters = newNode.data;
			}

			await dispatch(addNodeParameters(toDispatch));

			// Increment the counter
			await setCounter((prevCount) => prevCount + 1);

			console.log("New node", newNode);

			if (newNode.data.label === "Create variable") {
				console.log("New variable dropped", newNode);
				await setVariables((vars) => [
					...vars,
					{
						nodeId: newNode.id,
						variable: newNode.data.parameters[0].value,
						data: newNode,
						count: counter + 1,
						value: newNode.data.parameters[1].value,
						literalVariables: [newNode.data.parameters[0].value],
					},
				]);
			}

			// If the node is thread split, update the redux
			if (newNode.data.label === "Thread split") {
				await dispatch(addThread({
					id: newNode.data.id,
					data: newNode.data,
					parameters: node.parameters,
				}));
			}

			await setNodes((nds) => [...nds, newNode]);
		},
		[reactFlowInstance, counter],
	);

	const onNodeClick = (_, node) => {
		setIsSelectionChange(true);
		setSelectedNode(node.id);
		setHighlightedEdges(null);

		const connectedEdges = edges.filter(
			(edge) => edge.source === node.id || edge.target === node.id,
		);
		setHighlightedEdges(connectedEdges.map((edge) => edge.id));
	};

	const onEdgeClick = (_, edge) => {
		setIsSelectionChange(true);
		setSelectedNode(null);
		setHighlightedEdges([edge.id]);
	};

	const updateEdgeTypes = (newType) => {
		setEdges((currentEdges) => currentEdges.map((edge) => ({
			...edge,
			type: newType,
		})));
	};

	const handleEdgeStyleChange = (event) => {
		const selectedStyle = event.target.value;
		setEdgeStyle(selectedStyle);
		updateEdgeTypes(selectedStyle);
	};

	const handleClearLogs = () => {
		dispatch(cleanLogs());
	};

	const envpopResize = () => {
		setEnvpopSize(envpopSize === "small" ? "large" : "small");
		if (envpopRef.current) {
			envpopRef.current.onResize();
		}
	};

	// eslint-disable-next-line unicorn/consistent-function-scoping
	const groupByDevice = (dataArray) => dataArray.reduce((acc, item) => {
		const [entity] = item.split('.');
		acc[entity] = acc[entity] || [];
		acc[entity].push(item);
		return acc;
	}, {});

	useEffect(() => {
		setInSimulation(props.inSimulation);
	}, [props.inSimulation]);

	return (
		<>
			<Spinner open={isLoading} />
			<Grid
				item
				container
				display="flex"
				alignItems="center"
				justifyContent="space-between"
				p={1}
			>
				<Grid
					item
					container
					xs={2}
					display="flex"
					direction="row"
					alignContent="flex-start"
					justifyContent="center"
					overflow="auto"
					height="calc(100vh - 280px)"
					p={3}
					pt={0}
					sx={{
						border: "1px solid black",
						borderRadius: "20px",
						backgroundColor: "#222",
					}}
				>
					<Chip
						label="Clear all"
						color="warning"
						variant="outlined"
						sx={{
							width: "90%",
							marginTop: "10px",
							cursor: "pointer",
						}}
						onClick={async () => {
							console.log("Clear all");
							await setNodes([]);
							await setEdges([]);
							setVariables([]);
							await dispatch(setThreads([]));
							await dispatch(setBrokers([]));
							await dispatch(setNodesParameters([]));
							await dispatch(cleanLogs());
						}}
					/>
					<Grid
						item
						container
						style={{
							width: "90%",
							color: "white",
						}}
						justifyContent="center"
					>
						<Typography
							style={{
								width: "100%",
								fontSize: "1em",
								fontWeight: "bold",
								padding: "5px",
								margin: "1px",
								marginTop: "5px",
								borderRadius: "5px",
							}}
						>
							{"Select Edge Style"}
						</Typography>
						<Select
							value={edgeStyle}
							style={{
								width: "100%",
								height: "50%",
								backgroundColor: "#444",
								border: "1px solid #888",
								borderRadius: "5px",
								padding: "5px",
							}}
							MenuProps={{
								PaperProps: {
									style: {
										backgroundColor: "white",
										color: "black",
									},
								},
							}}
							onChange={(event) => {
								handleEdgeStyleChange(event);
							}}
						>
							<MenuItem value="default">{"Default"}</MenuItem>
							<MenuItem value="straight">{"Straight"}</MenuItem>
							<MenuItem value="step">{"Step"}</MenuItem>
							<MenuItem value="smoothstep">{"Smoothstep"}</MenuItem>
						</Select>
					</Grid>
					{/* Toggle Button for Proximity */}
					<Grid
						item
						container
						justifyContent="center"
						alignItems="center"
						style={{ marginTop: "10px", width: "90%", color: "white" }}
					>
						<Typography
							style={{
								width: "100%",
								fontSize: "0.98em",
								fontWeight: "bold",
								padding: "5px",
								margin: "1px",
								marginTop: "5px",
								borderRadius: "5px",
							}}
						>
							{"Proximity Connection"}
						</Typography>
						<Switch
							checked={isProximityEnabled}
							onChange={() => setIsProximityEnabled((prev) => !prev)}
							sx={{
								'& .MuiSwitch-switchBase.Mui-checked': {
									color: '#2ba44b',
								},
								'& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
									backgroundColor: '#2ba44b',
								},
								'& .MuiSwitch-switchBase': {
									color: '#04598c',
								},
								'& .MuiSwitch-switchBase + .MuiSwitch-track': {
									backgroundColor: '#BAC1C5',
								},
							}}
						/>

					</Grid>

					{/* Parse all toolboxes and nodes */}
					{toolboxes.map((toolbox) => (
						<Grid
							key={toolbox.name}
							item
							container
							style={{
								width: "90%",
							}}
							justifyContent="center"
						>
							<Typography
								style={{
									width: "100%",
									color: toolbox.fontColor ?? "black",
									fontSize: "1em",
									fontWeight: "bold",
									padding: "5px",
									margin: "1px",
									marginTop: "5px",
									borderRadius: "5px",
								}}
							>
								{toolbox.name}
							</Typography>
							{toolbox.nodes.map((node) => (
								<Typography
									key={node.name}
									draggable
									hidden={node.action && node.action.type === "subscribe"}
									style={{
										width: "100%",
										backgroundColor: toolbox.backgroundColor ?? "#fff",
										toolboxColor: toolbox.backgroundColor ?? "black",
										color: toolbox.fontColor ?? "black",
										border: "1px solid black",
										padding: "5px",
										margin: "1px",
										borderRadius: "5px",
										cursor: "pointer",
										fontSize: "0.9em",
									}}
									textAlign="center"
									onDragStart={(event) => {
										node.toolbox = toolbox.name;
										node.backgroundColor = toolbox.backgroundColor;
										node.toolboxColor = toolbox.backgroundColor;
										node.fontColor = toolbox.fontColor;
										onDragStart(event, node);
									}}
								>
									{node.name}
								</Typography>
							))}
						</Grid>
					))}
				</Grid>
				<Grid
					item
					container
					xs={6.9}
					display="flex"
					direction="row"
					alignItems="center"
					justifyContent="center"
					sx={{
						backgroundColor: "black",
						border: "1px solid black",
						borderRadius: "20px",
					}}
				>
					<ReactFlowProvider>
						<div
							ref={reactFlowWrapper}
							className="reactflow-wrapper"
							style={{
								width: '100%',
								height: "calc(100vh - 280px)",
							}}
						>
							<ReactFlow
								nodes={nodes}
								edges={ghostEdge ? [...edges, ghostEdge] : edges}
								nodeTypes={nodeTypes}
								onNodesChange={onNodesChange}
								onNodesDelete={onNodesDelete}
								onEdgesChange={onEdgesChange}
								onConnect={onConnect}
								onInit={setReactFlowInstance}
								onDrop={onDrop}
								onDragOver={onDragOver}
								onNodeClick={onNodeClick}
								onEdgeClick={onEdgeClick}
								onNodeDrag={onNodeDrag}
								onNodeDragStop={onNodeDragStop}
							// onNodeMouseEnter={() => setIsSelectionChange(true)}
							>
								<MiniMap
									pannable
									zoomable
									nodeStrokeWidth={3}
									position="bottom-left"
									maskColor="rgba(0,0,0,0.5)"
									nodeColor="rgba(150,0,0,0.5)"
								/>
								<Controls position="bottom-right" />
								<Background variant="dots" gap={12} size={1} />
							</ReactFlow>
						</div>
					</ReactFlowProvider>
				</Grid>
				<Grid
					item
					container
					xs={3}
					display="flex"
					direction="row"
					alignContent="flex-start"
					justifyContent="flex-start"
					overflow="auto"
					height="calc(100vh - 280px)"
					p={2}
					sx={{
						border: "1px solid black",
						borderRadius: "20px",
						backgroundColor: "#0b0d0f",
					}}
				>
					{validationErrors?.payload?.length > 0 && (
						<>
							<Grid item width="100%">
								<Grid item width="100%" display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
									<Typography sx={{ color: "error.main", fontSize: "0.9rem", fontWeight: "bold" }}>
										{"Validation Errors"}
									</Typography>
								</Grid>
								{validationErrors.payload.map((error) => (
									<Typography
										key={Math.random()}
										sx={{
											color: "error.main",
											fontSize: "0.8rem",
											marginTop: "2px",
										}}
									>
										{`[Node ${error?.id}]: "${error.message}"`}
									</Typography>
								))}
							</Grid>
							<Divider
								style={{
									margin: "10px 0px",
									backgroundColor: "white!important",
									width: "100%",
									opacity: "0.5",
								}}
							/>
						</>
					)}
					<ReactJson
						displayDataTypes="false"
						theme="brewer"
						collapsed={1}
						name="Live Variables"
						quotesOnKeys={false}
						src={liveVariables}
						style={{
							fontSize: "0.8rem",
							fontColor: "white",
							width: "100%",
							// height: "100%",
						}}
					/>
					<Divider
						style={{
							margin: "10px 0px",
							backgroundColor: "white!important",
							width: "100%",
							opacity: "0.5",
						}}
					/>
					<Grid item width="100%">
						<Grid item width="100%" display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
							<Typography sx={{ color: "white!important", fontSize: "0.9rem", fontWeight: "bold" }}>
								{"Application Logs"}
							</Typography>
							{storeLogs.length > 0 && (
								<IconButton
									color="white"
									sx={{
										padding: 1,
										'&:hover': {
											backgroundColor: 'rgba(0, 0, 0, 0.08)',
										},
									}}
									onClick={handleClearLogs}
								>
									<Delete sx={{ fontSize: 20, color: "white!important" }} />
								</IconButton>
							)}
						</Grid>
						{[...storeLogs].sort(sortLogs).map((log) => (
							<Typography
								key={Math.random()}
								sx={{
									color: "white!important",
									fontSize: "0.8rem",
									marginTop: "2px",
								}}
							>
								{"["}
								{log.payload.timestamp}
								{"] "}
								{log.payload.message}
								{" (Node "}
								{log.payload.node_count}
								{")"}
							</Typography>
						))}
						{storeLogs.length === 0 && (
							<Typography
								variant="body2"
								sx={{
									color: "white!important",
									fontSize: "0.9rem",
									marginTop: "2px",
									opacity: "0.5",
								}}
							>
								{"No logs exist"}
							</Typography>
						)}
					</Grid>
					<Divider
						style={{
							margin: "10px 0px",
							backgroundColor: "white!important",
							width: "100%",
							opacity: "0.5",
						}}
					/>
					<ReactJson
						displayDataTypes={false}
						theme="brewer"
						collapsed={2}
						name="Custom Variables"
						quotesOnKeys={false}
						src={customVariables}
						style={{
							fontSize: "0.8rem",
							fontColor: "white",
							width: "100%",
							// height: "100%",
						}}
					/>
					<Divider
						style={{
							margin: "10px 0px",
							backgroundColor: "white!important",
							width: "100%",
							opacity: "0.5",
						}}
					/>
					<ReactJson
						displayDataTypes={false}
						theme="brewer"
						collapsed={1}
						name="Variables"
						quotesOnKeys={false}
						src={groupByDevice(toolboxVariables)}
						style={{
							fontSize: "0.8rem",
							fontColor: "white",
							width: "100%",
							// height: "100%",
						}}
					/>
				</Grid>
			</Grid>
			{envpopModel !== null && inSimulation && (
				<Grid
					style={{
						position: "absolute",
						width: envpopSize === "small" ? "600px" : "1200px",
						height: envpopSize === "small" ? "400px" : "100%",
						bottom: "0px",
						left: "0px",
						margin: "0px",
						padding: "0px",
						zIndex: 1000,
						opacity: 1,
					}}
				>
					<CreateMission
						ref={envpopRef}
						inPopup
						inSimulation={inSimulation}
						model={envpopModel?.model_text}
						dbitem={envpopModel}
					/>
					<div
						style={{
							position: "absolute",
							top: "0px",
							right: "0px",
							cursor: "pointer",
						}}
					>
						<IconButton aria-label={envpopSize === "small" ? "Expand" : "Shrink"} className="simulationPopupClose" onClick={envpopResize}>
							{envpopSize === "small" ? <ZoomOutMap sx={{ color: "#666666" }} /> : <ZoomInMap sx={{ color: "#666666" }} />}
						</IconButton>
					</div>
				</Grid>
			)}
		</>
	);
};

export default memo(Testbed);
