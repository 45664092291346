import ambientLightIcon from "../../../assets/images/missionItems/sensors/ambientLight.png";
import areaAlarmIcon from "../../../assets/images/missionItems/sensors/areaAlarm.png";
import cameraIcon from "../../../assets/images/missionItems/sensors/camera.png";
import distanceIcon from "../../../assets/images/missionItems/sensors/distance.png";
import gasIcon from "../../../assets/images/missionItems/sensors/gas.png";
import humidityIcon from "../../../assets/images/missionItems/sensors/humidity.png";
import linearAlarmIcon from "../../../assets/images/missionItems/sensors/alarm.png";
import linearAlarmStart from "../../../assets/images/missionItems/sensors/alarmStart.png";
import linearAlarmEnd from "../../../assets/images/missionItems/sensors/alarmEnd.png";
import microphoneIcon from "../../../assets/images/missionItems/sensors/microphone.png";
import phIcon from "../../../assets/images/missionItems/sensors/ph.png";
import temperatureIcon from "../../../assets/images/missionItems/sensors/temperature.png";

const sensors = {
	title: "Sensors",
	items: {
		ambientLight: {
			icon: ambientLightIcon,
			variables: {
				hz: {
					type: "number",
					name: "Frequency",
					value: 3,
					min: 0,
					max: 10,
					step: 1,
					precision: 0,
					format: "Hz",
					tooltipDisplay: false,
					metersDisplay: false,
				},
				mode: {
					type: "select",
					name: "Operation Mode",
					value: "simulation",
					options: ["simulation", "mock"],
					tooltipDisplay: false,
					metersDisplay: false,
				},
				operation: {
					type: "select",
					name: "Operation Type",
					value: "sinus",
					options: ["constant", "random", "triangle", "normal", "sinus"],
					needs: { mode: ["mock"] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				constant: {
					type: "number",
					name: "Constant Value",
					value: 100,
					min: 0,
					step: 0.1,
					precision: 1,
					format: "Hz",
					needs: { mode: ["mock"], operation: ["constant"] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				min: {
					type: "number",
					name: "Min Value",
					value: 30,
					min: 0,
					max: "max",
					step: 0.1,
					precision: 1,
					format: "Hz",
					needs: { mode: ["mock"], operation: ["random", "triangle"] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				max: {
					type: "number",
					name: "Max Value",
					value: 70,
					min: "min",
					step: 0.1,
					precision: 1,
					format: "Hz",
					needs: { mode: ["mock"], operation: ["random", "triangle"] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				step: {
					type: "number",
					name: "Step Value",
					value: 0.01,
					min: 0,
					step: 0.01,
					precision: 2,
					format: "Hz",
					needs: { mode: ["mock"], operation: ["triangle", "sinus"] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				mean: {
					type: "number",
					name: "Mean Value",
					value: 60,
					min: 0,
					step: 0.01,
					precision: 2,
					format: "Hz",
					needs: { mode: ["mock"], operation: ["normal"] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				std: {
					type: "number",
					name: "Standard Deviation",
					value: 5,
					min: 0,
					step: 0.01,
					precision: 2,
					needs: { mode: ["mock"], operation: ["normal"] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				dc: {
					type: "number",
					name: "DC Value",
					value: 60,
					min: 0,
					step: 1,
					precision: 0,
					format: "Hz",
					needs: { mode: ["mock"], operation: ["sinus"] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				amplitude: {
					type: "number",
					name: "Amplitude Value",
					value: 10,
					min: 0,
					step: 1,
					precision: 0,
					format: "Hz",
					needs: { mode: ["mock"], operation: ["sinus"] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
			},
			tooltip: {
				header: "Ambient Light",
				body: "An ambient light sensor measures the amount of ambient light (luminosity) of its surrounding area",
			},
		},
		areaAlarm: {
			icon: areaAlarmIcon,
			variables: {
				hz: {
					type: "number",
					name: "Frequency",
					value: 1,
					min: 0,
					max: 10,
					step: 1,
					precision: 0,
					format: "Hz",
					tooltipDisplay: false,
					metersDisplay: false,
				},
				range: {
					type: "number",
					name: "Range",
					value: 10,
					min: 0,
					step: 1,
					precision: 0,
					format: "m",
					tooltipDisplay: false,
					metersDisplay: true,
				},
				mode: {
					type: "select",
					name: "Operation Mode",
					value: "simulation",
					options: ["simulation", "mock"],
					tooltipDisplay: false,
					metersDisplay: false,
				},
			},
			tooltip: {
				header: "Area Alarm",
				body: "An area alarm detects robots that go through the sensors radius",
			},
		},
		camera: {
			icon: cameraIcon,
			variables: {
				theta: {
					type: "number",
					name: "Angle",
					value: 0,
					min: -180,
					max: 180,
					step: 5,
					precision: 0,
					format: "°",
					tooltipDisplay: false,
					metersDisplay: false,
				},
				hz: {
					type: "number",
					name: "Frequency",
					value: 3,
					min: 0,
					max: 10,
					step: 1,
					precision: 0,
					format: "Hz",
					tooltipDisplay: false,
					metersDisplay: false,
				},
				fov: {
					type: "number",
					name: "Field of View",
					value: 90,
					min: 0,
					max: 180,
					step: 1,
					precision: 0,
					format: "°",
					tooltipDisplay: false,
					metersDisplay: false,
				},
				range: {
					type: "number",
					name: "Range",
					value: 20,
					min: 0,
					step: 1,
					precision: 0,
					format: "m",
					tooltipDisplay: false,
					metersDisplay: true,
				},
				realImages: {
					type: "boolean",
					name: "Generate Real Images",
					value: false,
					tooltipDisplay: false,
					metersDisplay: false,
				},
				width: {
					type: "number",
					name: "Width",
					value: 640,
					min: 0,
					step: 1,
					precision: 0,
					format: "px",
					tooltipDisplay: false,
					metersDisplay: false,
				},
				height: {
					type: "number",
					name: "Height",
					value: 480,
					min: 0,
					step: 1,
					precision: 0,
					format: "px",
					tooltipDisplay: false,
					metersDisplay: false,
				},
				mode: {
					type: "select",
					name: "Operation Mode",
					value: "simulation",
					options: ["simulation", "mock"],
					tooltipDisplay: false,
					metersDisplay: false,
				},
				onPanTilt: {
					type: "boolean",
					name: "Pan Tilt",
					value: false,
					tooltipDisplay: false,
					metersDisplay: false,
				},
				panMin: {
					type: "number",
					name: "Pan Min",
					value: -90,
					min: -180,
					max: "panMax",
					step: 10,
					precision: 0,
					format: "°",
					needs: { onPanTilt: [true] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				panMax: {
					type: "number",
					name: "Pan Max",
					value: 90,
					min: "panMin",
					max: 180,
					step: 10,
					precision: 0,
					format: "°",
					needs: { onPanTilt: [true] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				tiltMin: {
					type: "number",
					name: "Tilt Min",
					value: -90,
					min: -180,
					max: "tiltMax",
					step: 10,
					precision: 0,
					format: "°",
					needs: { onPanTilt: [true] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				tiltMax: {
					type: "number",
					name: "Tilt Max",
					value: 90,
					min: "tiltMin",
					max: 180,
					step: 10,
					precision: 0,
					format: "°",
					needs: { onPanTilt: [true] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				panTiltMode: {
					type: "select",
					name: "Pan Tilt Operation Mode",
					value: "simulation",
					options: ["simulation", "mock"],
					needs: { onPanTilt: [true] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				panTiltOperation: {
					type: "select",
					name: "Pan Tilt Operation Type",
					value: "sinus",
					options: ["sinus"],
					needs: { panTiltMode: ["mock"], onPanTilt: [true] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				panTiltHz: {
					type: "number",
					name: "Pan Tilt Frequency",
					value: 3,
					min: 0,
					max: 10,
					step: 1,
					precision: 0,
					format: "Hz",
					needs: { panTiltOperation: ["sinus"], panTiltMode: ["mock"], onPanTilt: [true] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				panTiltStep: {
					type: "number",
					name: "Pan Tilt Step",
					value: 0.01,
					min: 0,
					step: 0.01,
					precision: 2,
					format: "°",
					needs: { panTiltOperation: ["sinus"], panTiltMode: ["mock"], onPanTilt: [true] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				host: {
					type: "string",
					name: "Host",
					value: null,
					hide: true,
					tooltipDisplay: false,
					metersDisplay: false,
				},
			},
			tooltip: {
				header: "Camera",
				body: "A camera captures an image defined by its field of view and range, which can be used to perform various detections (human, QR etc.)",
			},
		},
		distance: {
			icon: distanceIcon,
			variables: {
				theta: {
					type: "number",
					name: "Angle",
					value: 0,
					min: 0,
					max: 360,
					step: 10,
					precision: 0,
					format: "°",
					tooltipDisplay: false,
					metersDisplay: false,
				},
				hz: {
					type: "number",
					name: "Frequency",
					value: 3,
					min: 0,
					max: 10,
					step: 1,
					precision: 0,
					format: "Hz",
					tooltipDisplay: false,
					metersDisplay: false,
				},
				range: {
					type: "number",
					name: "Range",
					value: 20,
					min: 0,
					step: 1,
					precision: 0,
					format: "m",
					tooltipDisplay: false,
					metersDisplay: true,
				},
				mode: {
					type: "select",
					name: "Operation Mode",
					value: "simulation",
					options: ["simulation", "mock"],
					tooltipDisplay: false,
					metersDisplay: false,
				},
				operation: {
					type: "select",
					name: "Operation Type",
					value: "sinus",
					options: ["constant", "random", "triangle", "normal", "sinus"],
					needs: { mode: ["mock"] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				constant: {
					type: "number",
					name: "Constant Value",
					value: 100,
					min: 0,
					step: 0.1,
					precision: 1,
					format: "Hz",
					needs: { mode: ["mock"], operation: ["constant"] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				min: {
					type: "number",
					name: "Min Value",
					value: 30,
					min: 0,
					max: "max",
					step: 0.1,
					precision: 1,
					format: "Hz",
					needs: { mode: ["mock"], operation: ["random", "triangle"] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				max: {
					type: "number",
					name: "Max Value",
					value: 70,
					min: "min",
					step: 0.1,
					precision: 1,
					format: "Hz",
					needs: { mode: ["mock"], operation: ["random", "triangle"] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				step: {
					type: "number",
					name: "Step Value",
					value: 0.01,
					min: 0,
					step: 0.01,
					precision: 2,
					format: "Hz",
					needs: { mode: ["mock"], operation: ["triangle", "sinus"] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				mean: {
					type: "number",
					name: "Mean Value",
					value: 60,
					min: 0,
					step: 0.01,
					precision: 2,
					format: "Hz",
					needs: { mode: ["mock"], operation: ["normal"] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				std: {
					type: "number",
					name: "Standard Deviation",
					value: 5,
					min: 0,
					step: 0.01,
					precision: 2,
					needs: { mode: ["mock"], operation: ["normal"] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				dc: {
					type: "number",
					name: "DC Value",
					value: 60,
					min: 0,
					step: 1,
					precision: 0,
					format: "Hz",
					needs: { mode: ["mock"], operation: ["sinus"] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				amplitude: {
					type: "number",
					name: "Amplitude Value",
					value: 10,
					min: 0,
					step: 1,
					precision: 0,
					format: "Hz",
					needs: { mode: ["mock"], operation: ["sinus"] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				onPanTilt: {
					type: "boolean",
					name: "Pan Tilt",
					value: false,
					tooltipDisplay: false,
					metersDisplay: false,
				},
				panMin: {
					type: "number",
					name: "Pan Min",
					value: -90,
					min: -180,
					max: "panMax",
					step: 10,
					precision: 0,
					format: "°",
					needs: { onPanTilt: [true] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				panMax: {
					type: "number",
					name: "Pan Max",
					value: 90,
					min: "panMin",
					max: 180,
					step: 10,
					precision: 0,
					format: "°",
					needs: { onPanTilt: [true] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				tiltMin: {
					type: "number",
					name: "Tilt Min",
					value: -90,
					min: -180,
					max: "tiltMax",
					step: 10,
					precision: 0,
					format: "°",
					needs: { onPanTilt: [true] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				tiltMax: {
					type: "number",
					name: "Tilt Max",
					value: 90,
					min: "tiltMin",
					max: 180,
					step: 10,
					precision: 0,
					format: "°",
					needs: { onPanTilt: [true] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				panTiltMode: {
					type: "select",
					name: "Pan Tilt Operation Mode",
					value: "simulation",
					options: ["simulation", "mock"],
					needs: { onPanTilt: [true] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				panTiltOperation: {
					type: "select",
					name: "Pan Tilt Operation Type",
					value: "sinus",
					options: ["sinus"],
					needs: { panTiltMode: ["mock"], onPanTilt: [true] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				panTiltHz: {
					type: "number",
					name: "Pan Tilt Frequency",
					value: 3,
					min: 0,
					max: 10,
					step: 1,
					precision: 0,
					format: "Hz",
					needs: { panTiltOperation: ["sinus"], panTiltMode: ["mock"], onPanTilt: [true] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				panTiltStep: {
					type: "number",
					name: "Pan Tilt Step",
					value: 0.01,
					min: 0,
					step: 0.01,
					precision: 2,
					format: "°",
					needs: { panTiltOperation: ["sinus"], panTiltMode: ["mock"], onPanTilt: [true] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				host: {
					type: "string",
					name: "Host",
					value: null,
					hide: true,
					tooltipDisplay: false,
					metersDisplay: false,
				},
			},
			tooltip: {
				header: "Distance",
				body: "A distance sensor measures the distance of the closest item up until its nominal range",
			},
		},
		gas: {
			icon: gasIcon,
			variables: {
				hz: {
					type: "number",
					name: "Frequency",
					value: 1,
					min: 0,
					max: 10,
					step: 1,
					precision: 0,
					format: "Hz",
					tooltipDisplay: false,
					metersDisplay: false,
				},
				mode: {
					type: "select",
					name: "Operation Mode",
					value: "simulation",
					options: ["simulation", "mock"],
					tooltipDisplay: false,
					metersDisplay: false,
				},
				operation: {
					type: "select",
					name: "Operation Type",
					value: "sinus",
					options: ["constant", "random", "triangle", "normal", "sinus"],
					needs: { mode: ["mock"] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				constant: {
					type: "number",
					name: "Constant Value",
					value: 100,
					min: 0,
					step: 0.1,
					precision: 1,
					format: "Hz",
					needs: { mode: ["mock"], operation: ["constant"] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				min: {
					type: "number",
					name: "Min Value",
					value: 30,
					min: 0,
					max: "max",
					step: 0.1,
					precision: 1,
					format: "Hz",
					needs: { mode: ["mock"], operation: ["random", "triangle"] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				max: {
					type: "number",
					name: "Max Value",
					value: 70,
					min: "min",
					step: 0.1,
					precision: 1,
					format: "Hz",
					needs: { mode: ["mock"], operation: ["random", "triangle"] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				step: {
					type: "number",
					name: "Step Value",
					value: 0.01,
					min: 0,
					step: 0.01,
					precision: 2,
					format: "Hz",
					needs: { mode: ["mock"], operation: ["triangle", "sinus"] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				mean: {
					type: "number",
					name: "Mean Value",
					value: 60,
					min: 0,
					step: 0.01,
					precision: 2,
					format: "Hz",
					needs: { mode: ["mock"], operation: ["normal"] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				std: {
					type: "number",
					name: "Standard Deviation",
					value: 5,
					min: 0,
					step: 0.01,
					precision: 2,
					needs: { mode: ["mock"], operation: ["normal"] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				dc: {
					type: "number",
					name: "DC Value",
					value: 60,
					min: 0,
					step: 1,
					precision: 0,
					format: "Hz",
					needs: { mode: ["mock"], operation: ["sinus"] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				amplitude: {
					type: "number",
					name: "Amplitude Value",
					value: 10,
					min: 0,
					step: 1,
					precision: 0,
					format: "Hz",
					needs: { mode: ["mock"], operation: ["sinus"] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
			},
			tooltip: {
				header: "Gas",
				body: "A gas sensor detects the presence of CO2 in its surrounding area",
			},
		},
		humidity: {
			icon: humidityIcon,
			variables: {
				hz: {
					type: "number",
					name: "Frequency",
					value: 1,
					min: 0,
					max: 10,
					step: 1,
					precision: 0,
					format: "Hz",
					tooltipDisplay: false,
					metersDisplay: false,
				},
				mode: {
					type: "select",
					name: "Operation Mode",
					value: "simulation",
					options: ["simulation", "mock"],
					tooltipDisplay: false,
					metersDisplay: false,
				},
				operation: {
					type: "select",
					name: "Operation Type",
					value: "sinus",
					options: ["constant", "random", "triangle", "normal", "sinus"],
					needs: { mode: ["mock"] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				constant: {
					type: "number",
					name: "Constant Value",
					value: 100,
					min: 0,
					step: 0.1,
					precision: 1,
					format: "Hz",
					needs: { mode: ["mock"], operation: ["constant"] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				min: {
					type: "number",
					name: "Min Value",
					value: 30,
					min: 0,
					max: "max",
					step: 0.1,
					precision: 1,
					format: "Hz",
					needs: { mode: ["mock"], operation: ["random", "triangle"] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				max: {
					type: "number",
					name: "Max Value",
					value: 70,
					min: "min",
					step: 0.1,
					precision: 1,
					format: "Hz",
					needs: { mode: ["mock"], operation: ["random", "triangle"] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				step: {
					type: "number",
					name: "Step Value",
					value: 0.01,
					min: 0,
					step: 0.01,
					precision: 2,
					format: "Hz",
					needs: { mode: ["mock"], operation: ["triangle", "sinus"] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				mean: {
					type: "number",
					name: "Mean Value",
					value: 60,
					min: 0,
					step: 0.01,
					precision: 2,
					format: "Hz",
					needs: { mode: ["mock"], operation: ["normal"] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				std: {
					type: "number",
					name: "Standard Deviation",
					value: 5,
					min: 0,
					step: 0.01,
					precision: 2,
					needs: { mode: ["mock"], operation: ["normal"] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				dc: {
					type: "number",
					name: "DC Value",
					value: 60,
					min: 0,
					step: 1,
					precision: 0,
					format: "Hz",
					needs: { mode: ["mock"], operation: ["sinus"] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				amplitude: {
					type: "number",
					name: "Amplitude Value",
					value: 10,
					min: 0,
					step: 1,
					precision: 0,
					format: "Hz",
					needs: { mode: ["mock"], operation: ["sinus"] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
			},
			tooltip: {
				header: "Humidity",
				body: "A humidity sensor measures the humidity level in its surrounding area",
			},
		},
		linearAlarm: {
			icon: linearAlarmIcon,
			itemType: "double",
			variables: {
				hz: {
					type: "number",
					name: "Frequency",
					value: 1,
					min: 0,
					max: 10,
					step: 1,
					precision: 0,
					format: "Hz",
					tooltipDisplay: false,
					metersDisplay: false,
				},
				mode: {
					type: "select",
					name: "Operation Mode",
					value: "simulation",
					options: ["simulation", "mock"],
					tooltipDisplay: false,
					metersDisplay: false,
				},
			},
			icons: {
				1: linearAlarmStart,
				2: linearAlarmEnd,
			},
			tooltip: {
				header: "Linear Alarm",
				body: "A linear alarm detects robots that go through the sensors radius",
			},
		},
		microphone: {
			icon: microphoneIcon,
			variables: {
				mode: {
					type: "select",
					name: "Operation Mode",
					value: "simulation",
					options: ["simulation", "mock"],
					tooltipDisplay: false,
					metersDisplay: false,
				},
			},
			tooltip: {
				header: "Microphone",
				body: "A microphone records any voice or sound present in the area it is placed",
			},
		},
		ph: {
			icon: phIcon,
			variables: {
				hz: {
					type: "number",
					name: "Frequency",
					value: 1,
					min: 0,
					max: 10,
					step: 1,
					precision: 0,
					format: "Hz",
					tooltipDisplay: false,
					metersDisplay: false,
				},
				mode: {
					type: "select",
					name: "Operation Mode",
					value: "simulation",
					options: ["simulation", "mock"],
					tooltipDisplay: false,
					metersDisplay: false,
				},
				operation: {
					type: "select",
					name: "Operation Type",
					value: "sinus",
					options: ["constant", "random", "triangle", "normal", "sinus"],
					needs: { mode: ["mock"] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				constant: {
					type: "number",
					name: "Constant Value",
					value: 100,
					min: 0,
					step: 0.1,
					precision: 1,
					format: "Hz",
					needs: { mode: ["mock"], operation: ["constant"] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				min: {
					type: "number",
					name: "Min Value",
					value: 30,
					min: 0,
					max: "max",
					step: 0.1,
					precision: 1,
					format: "Hz",
					needs: { mode: ["mock"], operation: ["random", "triangle"] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				max: {
					type: "number",
					name: "Max Value",
					value: 70,
					min: "min",
					step: 0.1,
					precision: 1,
					format: "Hz",
					needs: { mode: ["mock"], operation: ["random", "triangle"] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				step: {
					type: "number",
					name: "Step Value",
					value: 0.01,
					min: 0,
					step: 0.01,
					precision: 2,
					format: "Hz",
					needs: { mode: ["mock"], operation: ["triangle", "sinus"] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				mean: {
					type: "number",
					name: "Mean Value",
					value: 60,
					min: 0,
					step: 0.01,
					precision: 2,
					format: "Hz",
					needs: { mode: ["mock"], operation: ["normal"] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				std: {
					type: "number",
					name: "Standard Deviation",
					value: 5,
					min: 0,
					step: 0.01,
					precision: 2,
					needs: { mode: ["mock"], operation: ["normal"] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				dc: {
					type: "number",
					name: "DC Value",
					value: 60,
					min: 0,
					step: 1,
					precision: 0,
					format: "Hz",
					needs: { mode: ["mock"], operation: ["sinus"] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				amplitude: {
					type: "number",
					name: "Amplitude Value",
					value: 10,
					min: 0,
					step: 1,
					precision: 0,
					format: "Hz",
					needs: { mode: ["mock"], operation: ["sinus"] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
			},
			tooltip: {
				header: "PH",
				body: "A pH sensor measures the pH scale in the area it is placed",
			},
		},
		temperature: {
			icon: temperatureIcon,
			variables: {
				hz: {
					type: "number",
					name: "Frequency",
					value: 1,
					min: 0,
					max: 10,
					step: 1,
					precision: 0,
					format: "Hz",
					tooltipDisplay: false,
					metersDisplay: false,
				},
				mode: {
					type: "select",
					name: "Operation Mode",
					value: "simulation",
					options: ["simulation", "mock"],
					tooltipDisplay: false,
					metersDisplay: false,
				},
				operation: {
					type: "select",
					name: "Operation Type",
					value: "sinus",
					options: ["constant", "random", "triangle", "normal", "sinus"],
					needs: { mode: ["mock"] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				constant: {
					type: "number",
					name: "Constant Value",
					value: 100,
					min: 0,
					step: 0.1,
					precision: 1,
					format: "Hz",
					needs: { mode: ["mock"], operation: ["constant"] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				min: {
					type: "number",
					name: "Min Value",
					value: 30,
					min: 0,
					max: "max",
					step: 0.1,
					precision: 1,
					format: "Hz",
					needs: { mode: ["mock"], operation: ["random", "triangle"] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				max: {
					type: "number",
					name: "Max Value",
					value: 70,
					min: "min",
					step: 0.1,
					precision: 1,
					format: "Hz",
					needs: { mode: ["mock"], operation: ["random", "triangle"] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				step: {
					type: "number",
					name: "Step Value",
					value: 0.01,
					min: 0,
					step: 0.01,
					precision: 2,
					format: "Hz",
					needs: { mode: ["mock"], operation: ["triangle", "sinus"] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				mean: {
					type: "number",
					name: "Mean Value",
					value: 60,
					min: 0,
					step: 0.01,
					precision: 2,
					format: "Hz",
					needs: { mode: ["mock"], operation: ["normal"] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				std: {
					type: "number",
					name: "Standard Deviation",
					value: 5,
					min: 0,
					step: 0.01,
					precision: 2,
					needs: { mode: ["mock"], operation: ["normal"] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				dc: {
					type: "number",
					name: "DC Value",
					value: 60,
					min: 0,
					step: 1,
					precision: 0,
					format: "Hz",
					needs: { mode: ["mock"], operation: ["sinus"] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				amplitude: {
					type: "number",
					name: "Amplitude Value",
					value: 10,
					min: 0,
					step: 1,
					precision: 0,
					format: "Hz",
					needs: { mode: ["mock"], operation: ["sinus"] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
			},
			tooltip: {
				header: "Temperature",
				body: "A temperature sensor measures the ambient temperature of its surrounding area",
			},
		},
	},
};

export default sensors;
