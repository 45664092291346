import CpsmlLight from "../assets/dsls-logos/cpsml_L.png";
import CpsmlDark from "../assets/dsls-logos/cpsml_D.png";

/**
 * Retrieves the CPSML constants.
 * @returns {Promise<Object>} The CPSML constants.
 */
export const getCpsmlConstants = async () => {
	const constants = {
		enabled: false,
		_id: "cpsml",
		name: "cps-ml",
		short: "cpsml",
		description: "DSL for Cyber-Physical Systems in the context of IoT",
		logoUrl: CpsmlLight,
		logoUrlDark: CpsmlDark,
		insertableEntities: {},
		shortDescription: "",
		documentation: "",
		subtypes: [],
		hasValidation: false,
		type: "Textual",
		canTransformTo: [],
		mustImport: [],
		canImport: [],
		supportsGeneration: true,
		generationLanguage: "",
		supportsDeployment: false,
		deploymentStoppable: false,
		generations: ["Default"],
		generationsOutputMap: {
			Default: "string",
		},
		keywords: [],
		llmSupport: false,
		tags: ["CPS", "Textual"],
		initiallyCollapsed: false,
	};
	return constants;
};
