import { useDispatch } from 'react-redux';
import { updateBroker } from './slice-brokers.js';

import {
	Grid,
	Typography,
	TextField,
} from "@mui/material";

const ParametersInput = ({ param, parameters, setParameters, nodeData }) => {
	const dispatch = useDispatch();
	return (
		<Grid
			item
			container
			display="flex"
			direction="row"
			justifyContent="flex-start"
			alignItems="center"
			sx={{
				marginBottom: "5px",
				width: "100%",
			}}
		>
			<Typography sx={{ marginRight: "10px", width: "40%" }}>
				{param.label}
				{/* {"("}
				{param.id}
				{")"} */}
			</Typography>
			<TextField
				type={param.type}
				placeholder={param.placeholder}
				disabled={param.locked ?? false}
				variant="filled"
				size="small"
				sx={{
					width: "55%",
					"& .MuiInputBase-input": {
						color: "white !important",
					},
				}}
				value={parameters.find((p) => p.id === param.id).value}
				onChange={(e) => {
					let inputValue = e.target.value;
					
					if (nodeData?.label === "Create variable" && param?.label === "Variable name") {
						const pythonicRegex = /^[a-zA-Z_][a-zA-Z0-9_]*$/;
						if (!pythonicRegex.test(inputValue)) {
							return;
						}
					}

					const newParams = parameters.map((p) => {
						if (p.id === param.id) {
							return {
								...p,
								value: inputValue,
							};
						}
						return p;
					});

					if (nodeData.label === "MQTT broker" && nodeData.toolbox === "Connectivity") {
						console.log("Broker inputs updated", newParams);
						dispatch(updateBroker({
							id: nodeData.id,
							params: newParams,
						}));
					}

					setParameters(newParams);
				}}
			/>
		</Grid>
	);
};

export default ParametersInput;
