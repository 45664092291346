import { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { Route, Routes, BrowserRouter as Router, useLocation } from "react-router-dom";
import { StyledEngineProvider, ThemeProvider, createTheme } from "@mui/material/styles";
import * as Sentry from "@sentry/browser";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ErrorBoundary } from "react-error-boundary";
import { CssBaseline } from "@mui/material";
import { Auth0Provider } from '@auth0/auth0-react';

import "./index.scss";
import colors from "./_colors.scss";
import "react-table-6/react-table.css";

// Components
import ErrorFallback from "./components/ErrorFallback.js";
import Footer from "./components/Footer.js";
import GuestOnly from "./components/GuestOnly.js";
import Header from "./components/Header.js";
import Protected from "./components/Protected.js";
import Snackbar from "./components/Snackbar.js";

// Screens
import Auth from "./screens/Auth.js";
import Collaboration from "./screens/Collaboration.js";
import DslModelScreen from "./screens/DslModelScreen.js";
import DslScreen from "./screens/DslScreen.js";
import Dsls from "./screens/Dsls.js";
import Examples from "./screens/Examples.js";
import Home from "./screens/Home.js";
import NotFound from "./screens/NotFound.js";
import ProjectScreen from "./screens/ProjectScreen.js";
import Projects from "./screens/Projects.js";
import Secrets from "./screens/Secrets.js";
import SignIn from "./screens/SignIn.js";
import Testbed from "./screens/Testbed.js";
import Unauthorized from "./screens/Unauthorized.js";
import Marketplace from "./screens/Marketplace.js";

// Examples
import AccordionExample from "./examples/Accordion.js";
import BrokerExample from "./examples/Broker.js";
import ButtonsExample from "./examples/Buttons.js";
import DatePickerExample from "./examples/DatePicker.js";
import DialogExample from "./examples/Dialog.js";
import DropdownExample from "./examples/Dropdown.js";
import FileUploadExample from "./examples/FileUpload.js";
import FormExample from "./examples/Form.js";
import LocalizationExample from "./examples/Localization.js";
import PlotExample from "./examples/Plot.js";
import PopupExample from "./examples/Popup.js";
import SearchExample from "./examples/Search.js";
import TableExample from "./examples/Table.js";
import ToastExample from "./examples/Toast.js";
import TooltipExample from "./examples/Tooltip.js";

import { adjustColors, jwt, colorSuggestions } from "./utils/index.js";

import { Provider } from 'react-redux';
import store from "./components/AppCreator/store.js";

import "./i18n.js";

const {
	NODE_ENV,
	REACT_APP_SENTRY_DSN,
	REACT_APP_SENTRY_ENVIRONMENT,
	REACT_APP_AUTH0_DOMAIN,
	REACT_APP_AUTH0_CLIENT_ID,
} = process.env;

Sentry.init({
	dsn: REACT_APP_SENTRY_DSN,
	environment: REACT_APP_SENTRY_ENVIRONMENT,
	ignoreErrors: [
		"ResizeObserver loop limit exceeded",
		"Non-Error promise rejection captured",
	],
	enabled: NODE_ENV === "production",
});

const theme = createTheme({
	palette: {
		primary: { main: colors.primary },
		secondary: { main: colors.secondary || colorSuggestions.secondary },
		third: { main: colors.third || colorSuggestions.third },

		primaryLight: { main: adjustColors(colors.primary, 100) },
		primaryDark: { main: adjustColors(colors.primary, -80) },
		secondaryLight: { main: adjustColors(colors.secondary || colorSuggestions.secondary, 100) },
		secondaryDark: { main: adjustColors(colors.secondary || colorSuggestions.secondary, -80) },
		thirdLight: { main: adjustColors(colors.third || colorSuggestions.third, 100) },
		thirdDark: { main: adjustColors(colors.third || colorSuggestions.third, -80) },

		success: { main: colors.success },
		error: { main: colors.error },
		warning: { main: colors.warning },
		info: { main: colors.info },

		dark: { main: colors.dark },
		light: { main: colors.light },
		grey: { main: colors.grey },
		greyDark: { main: colors.greyDark },
		green: { main: colors.green },
		white: { main: "#ffffff" },
	},
});

const App = () => {
	const location = useLocation();
	const [authenticated, setAuthenticated] = useState(false);

	useEffect(() => {
		setAuthenticated(jwt.isAuthenticated());
	}, [location]);

	return (
		<StyledEngineProvider injectFirst>
			<CssBaseline />
			<ThemeProvider theme={theme}>
				<ErrorBoundary FallbackComponent={ErrorFallback}>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<Header isAuthenticated={authenticated} />
						<main style={{ position: "relative", zIndex: 0, height: `calc(100vh - ${authenticated ? "160" : "70"}px)` }}>
							<Routes>
								<Route index element={<GuestOnly c={<SignIn />} />} />
								<Route path="auth" element={<GuestOnly c={<Auth />} />} />
								<Route path="home" element={<Protected c={<Home />} />} />
								<Route path="examples" element={<Protected c={<Examples />} />} />

								{/* Dsls general */}
								<Route path="dsls" element={<Protected c={<Dsls />} />} />
								<Route path="projects" element={<Protected c={<Projects />} />} />
								<Route path="projects/:projectid" element={<Protected c={<ProjectScreen />} />} />
								<Route path="secrets" element={<Protected c={<Secrets />} />} />
								<Route path="collaboration" element={<Protected c={<Collaboration />} />} />
								<Route path="marketplace" element={<Protected c={<Marketplace />} />} />

								{/* DSLs */}
								<Route path="dsls/envmaker" element={<Protected c={<DslScreen dsl="envmaker" />} />} />
								<Route path="dsls/envmaker/:modelid" element={<Protected c={<DslModelScreen dsl="envmaker" />} />} />
								<Route path="dsls/envpop" element={<Protected c={<DslScreen dsl="envpop" />} />} />
								<Route path="dsls/envpop/:modelid" element={<Protected c={<DslModelScreen dsl="envpop" />} />} />
								<Route path="dsls/smauto" element={<Protected c={<DslScreen dsl="smauto" />} />} />
								<Route path="dsls/smauto/:modelid" element={<Protected c={<DslModelScreen dsl="smauto" />} />} />
								<Route path="dsls/codintxt" element={<Protected c={<DslScreen dsl="codintxt" />} />} />
								<Route path="dsls/codintxt/:modelid" element={<Protected c={<DslModelScreen dsl="codintxt" />} />} />
								<Route path="dsls/openapi" element={<Protected c={<DslScreen dsl="openapi" />} />} />
								<Route path="dsls/openapi/:modelid" element={<Protected c={<DslModelScreen dsl="openapi" />} />} />
								<Route path="dsls/demol" element={<Protected c={<DslScreen dsl="demol" />} />} />
								<Route path="dsls/demol/:modelid" element={<Protected c={<DslModelScreen dsl="demol" />} />} />
								<Route path="dsls/xmasdsl" element={<Protected c={<DslScreen dsl="xmasdsl" />} />} />
								<Route path="dsls/xmasdsl/:modelid" element={<Protected c={<DslModelScreen dsl="xmasdsl" />} />} />
								<Route path="dsls/goaldsl" element={<Protected c={<DslScreen dsl="goaldsl" />} />} />
								<Route path="dsls/goaldsl/:modelid" element={<Protected c={<DslModelScreen dsl="goaldsl" />} />} />
								<Route path="dsls/cpsml" element={<Protected c={<DslScreen dsl="cpsml" />} />} />
								<Route path="dsls/cpsml/:modelid" element={<Protected c={<DslModelScreen dsl="cpsml" />} />} />
								<Route path="dsls/generos" element={<Protected c={<DslScreen dsl="generos" />} />} />
								<Route path="dsls/generos/:modelid" element={<Protected c={<DslModelScreen dsl="generos" />} />} />
								<Route path="dsls/rosbridgeml" element={<Protected c={<DslScreen dsl="rosbridgeml" />} />} />
								<Route path="dsls/rosbridgeml/:modelid" element={<Protected c={<DslModelScreen dsl="rosbridgeml" />} />} />
								<Route path="dsls/commidl" element={<Protected c={<DslScreen dsl="commidl" />} />} />
								<Route path="dsls/commidl/:modelid" element={<Protected c={<DslModelScreen dsl="commidl" />} />} />
								<Route path="dsls/toolboxer" element={<Protected c={<DslScreen dsl="toolboxer" />} />} />
								<Route path="dsls/toolboxer/:modelid" element={<Protected c={<DslModelScreen dsl="toolboxer" />} />} />
								<Route path="dsls/appcreator" element={<Protected c={<DslScreen dsl="appcreator" />} />} />
								<Route path="dsls/appcreator/:modelid" element={<Protected c={<DslModelScreen dsl="appcreator" />} />} />
								<Route path="dsls/dflow" element={<Protected c={<DslScreen dsl="dflow" />} />} />
								<Route path="dsls/dflow/:modelid" element={<Protected c={<DslModelScreen dsl="dflow" />} />} />

								{/* EXAMPLES */}
								<Route path="buttons" element={<Protected c={<ButtonsExample />} />} />
								<Route path="form" element={<Protected c={<FormExample />} />} />
								<Route path="dropdown" element={<Protected c={<DropdownExample />} />} />
								<Route path="tooltip" element={<Protected c={<TooltipExample />} />} />
								<Route path="table" element={<Protected c={<TableExample />} />} />
								<Route path="file-upload" element={<Protected c={<FileUploadExample />} />} />
								<Route path="broker" element={<Protected c={<BrokerExample />} />} />
								<Route path="plot" element={<Protected c={<PlotExample />} />} />
								<Route path="accordion" element={<Protected c={<AccordionExample />} />} />
								<Route path="search" element={<Protected c={<SearchExample />} />} />
								<Route path="toast" element={<Protected c={<ToastExample />} />} />
								<Route path="popup" element={<Protected c={<PopupExample />} />} />
								<Route path="dialog" element={<Protected c={<DialogExample />} />} />
								<Route path="datepicker" element={<Protected c={<DatePickerExample />} />} />
								<Route path="localization" element={<Protected c={<LocalizationExample />} />} />
								{/* END OF EXAMPLES */}

								<Route path="testbed" element={<Protected c={<Testbed />} />} />
								<Route path="*" element={<NotFound />} />
								<Route path="/unauthorized" element={<Unauthorized />} />
							</Routes>
						</main>
						{authenticated && <Footer />}
						<Snackbar />
					</LocalizationProvider>
				</ErrorBoundary>
			</ThemeProvider>
		</StyledEngineProvider>
	);
};

const root = ReactDOM.createRoot(document.querySelector("#root"));
root.render(
	<Provider store={store}>
		<Auth0Provider
			domain={REACT_APP_AUTH0_DOMAIN}
			clientId={REACT_APP_AUTH0_CLIENT_ID}
			authorizationParams={{
				redirect_uri: window.location.origin,
			}}
		>
			<Router>
				<App />
			</Router>
		</Auth0Provider>
	</Provider>,
);
