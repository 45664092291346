import blueprint from "./blueprint.js";
import city from "./city.js";
import farm from "./farm.js";
import home from "./home.js";
import nuclear from "./nuclear.js";
import superMarket from "./super-market.js";

const categories = {
	home,
	nuclear,
	city,
	farm,
	superMarket,
	blueprint,
};

export default categories;
