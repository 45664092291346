export const systemToolboxes = [
	{
		name: "Utilities",
		backgroundColor: "#666",
		fontColor: "#fff",
		nodes: [
			{
				name: "Start",
				inputs: 0,
				outputs: 1,
				parameters: [
					{
						id: "delay",
						type: "input",
						label: "Artificial delay",
						value: 0,
						required: true,
						show: true,
					},
				],
			},
			{
				name: "End",
				inputs: 1,
				outputs: 0,
			},
			{
				name: "Delay",
				inputs: 1,
				parameters: [
					{
						id: "time",
						type: "input",
						label: "Time",
						value: 1,
						required: true,
						show: true,
					},
				],
			},
			{
				name: "Thread split",
				inputs: 1,
				outputs: 2,
			},
			{
				name: "Thread join",
				inputs: 2,
				outputs: 1,
			},
			{
				name: "Condition",
				outputs: 2,
				parameters: [
					{
						id: "condition_1",
						type: "input",
						label: "Condition #1",
						required: true,
						value: "False",
					},
					{
						id: "default",
						type: "input",
						label: "Default",
						value: "True",
						locked: true,
					},
				],
			},
			{
				name: "Random",
				outputs: 2,
				parameters: [
					{
						id: "probability_1",
						type: "input",
						label: "Probability #1",
						required: true,
						value: 0.5,
						show: true,
					},
					{
						id: "probability_2",
						type: "input",
						label: "Probability #2",
						required: true,
						value: 0.5,
						show: true,
					},
				],
			},
			{
				name: "Preempt",
				inputs: 1,
				outputs: 1,
				parameters: [
					{
						id: "thread",
						type: "thread",
						label: "Thread to preempt",
						required: true,
						show: true,
					},
				],
			},
			{
				name: "Log",
				inputs: 1,
				parameters: [
					{
						id: "message",
						type: "input",
						label: "Message",
						required: true,
						show: true,
					},
				],
			},
		],
	},
	{
		name: "Advanced",
		backgroundColor: "#666",
		fontColor: "#fff",
		nodes: [
			{
				name: "Create variable",
				visibleParameters: true,
				inputs: 1,
				parameters: [
					{
						id: "variable",
						type: "input",
						label: "Variable name",
						required: true,
						show: true,
						value: `variable`,
					},
					{
						id: "value",
						type: "input",
						label: "Initial value",
						required: true,
						show: true,
						value: 0,
					},
				],
			},
			{
				name: "Set variable",
				inputs: 1,
				parameters: [
					{
						id: "variable",
						type: "input",
						label: "Variable name",
						required: true,
						show: true,
						value: "variable",
					},
					{
						id: "value",
						type: "input",
						label: "Value",
						required: true,
						show: true,
						value: "value",
					},
				],
			},
		],
	},
];
