import { useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Divider, Grid, Typography } from "@mui/material";
import Draggable from "react-draggable";

import { getElementDimension, getMapDimensions } from "./utilities.js";
import categories from "./categories/index.js";
import Slider from "../Slider.js";
import Switch from "../Switch.js";
import Accordion from "../Accordion.js";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		height: "100%",
		padding: "0px",
		margin: "0px",
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		overflowX: "hidden",
	},
	mapRoot: {
		width: "100%",
		height: "100%",
		padding: "0px",
		margin: "0px",
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: "rgba(255, 255, 255, 0.1)",
	},
	controlsRoot: {
		width: "100%",
		height: "100%",
		padding: "0px",
		margin: "0px",
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
		alignItems: "center",
		color: "white",
		overflowY: "auto",
		overflowX: "hidden",
	},
	map: {
		padding: "0px",
		margin: "0px",
		backgroundColor: "white",
		position: "relative",
	},
	sliderBox: {
		width: "100%",
		padding: "0px 20px",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
	},
	optionsRow: {
		width: "100%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	divider: {
		width: "90%",
		margin: "10px 0px",
		backgroundColor: theme.palette.greyDark.main,
	},
	tilesBox: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		justifyContent: "space-evenly",
		alignItems: "center",
	},
	menuTile: {
		width: "70px",
		margin: "5px",
		cursor: "move",
	},
	draggingTile: {
		width: "70px",
		height: "70px",
		opacity: 0.6,
		position: "absolute",
		cursor: "move",
		zIndex: 1,
	},
}));

const toRadians = (angle) => (
	angle * (Math.PI / 180)
);

const transformWall = (wall, boxPosition, boxRotation, mapPosition, nextId) => {
	const x1Box = boxPosition.width * (wall.x1 / 100);
	const y1Box = boxPosition.height * (wall.y1 / 100);
	const newX1Box = (x1Box - (boxPosition.width / 2)) * Math.cos(toRadians(-boxRotation))
		- (y1Box - (boxPosition.height / 2)) * Math.sin(toRadians(-boxRotation)) + (boxPosition.width / 2);
	const newY1Box = (x1Box - (boxPosition.width / 2)) * Math.sin(toRadians(-boxRotation))
		+ (y1Box - (boxPosition.height / 2)) * Math.cos(toRadians(-boxRotation)) + (boxPosition.height / 2);
	const x1Px = newX1Box + boxPosition.left - mapPosition.left;
	const x1 = (x1Px / mapPosition.width) * 100;
	const y1Px = newY1Box - boxPosition.bottom + mapPosition.bottom;
	const y1 = (y1Px / mapPosition.height) * 100;

	const x2Box = boxPosition.width * (wall.x2 / 100);
	const y2Box = boxPosition.height * (wall.y2 / 100);
	const newX2Box = (x2Box - (boxPosition.width / 2)) * Math.cos(toRadians(-boxRotation))
		- (y2Box - (boxPosition.height / 2)) * Math.sin(toRadians(-boxRotation)) + (boxPosition.width / 2);
	const newY2Box = (x2Box - (boxPosition.width / 2)) * Math.sin(toRadians(-boxRotation))
		+ (y2Box - (boxPosition.height / 2)) * Math.cos(toRadians(-boxRotation)) + (boxPosition.height / 2);
	const x2Px = newX2Box + boxPosition.left - mapPosition.left;
	const x2 = (x2Px / mapPosition.width) * 100;
	const y2Px = newY2Box - boxPosition.bottom + mapPosition.bottom;
	const y2 = (y2Px / mapPosition.height) * 100;

	return {
		x1, y1, x2, y2, id: nextId,
	};
};

const CreateMap = ({
	model: propsModel = null,
	modelUpdate: propsModelUpdate = () => {},
	inPopup: propsInPopup = false,
}) => {
	const classes = useStyles();

	const [nRows, setNRows] = useState(6);
	const [nCols, setNCols] = useState(9);
	const [boxes, setBoxes] = useState({});
	const [gridVisible, setGridVisible] = useState(true);
	const [mapWidth, setMapWidth] = useState(100);
	const [mapHeight, setMapHeight] = useState(80);
	const [compDragging, setCompDragging] = useState(null);
	const [dragging, setDragging] = useState(null);
	const [draggingCat, setDraggingCat] = useState(null);
	const [draggedItem, setDraggedItem] = useState({ x: 0, y: 0 });
	const [grid, setGrid] = useState([]);
	const [inPopup, setInPopup] = useState(propsInPopup);
	const compDraggingRef = useRef();

	const calculateAutoWalls = (newBoxes) => {
		const walls = [];
		const map = document.querySelector("#map");
		const mapPosition = map.getBoundingClientRect();
		let nextId = 1;
		for (const box of Object.keys(newBoxes)) {
			if (newBoxes[box].content !== null) {
				const boxDocument = document.querySelector(`#box_${box}`);
				const boxPosition = boxDocument.getBoundingClientRect();
				for (const wall of categories[newBoxes[box].contentCat].components[newBoxes[box].contentId].walls) {
					walls.push(transformWall(wall, boxPosition, newBoxes[box].rotation, mapPosition, nextId));
					nextId += 1;
				}
			}
		}

		return walls;
	};

	const updateModel = (values) => {
		const tmpModel = {
			nRows,
			nCols,
			boxes,
			gridVisible,
			mapWidth,
			mapHeight,
			compDragging,
			dragging,
			draggingCat,
			draggedItem,
		};

		for (const [key, value] of Object.entries(values)) {
			tmpModel[key] = value;
		}

		tmpModel.autoWalls = calculateAutoWalls(tmpModel.boxes);

		propsModelUpdate(JSON.stringify({ ...tmpModel }));
	};

	const changeMapDimensions = () => {
		const mainmap = document.querySelector("#mainmap");
		const mainmapWidth = mainmap.offsetWidth;
		const mainmapHeight = mainmap.offsetHeight;
		const { width, height } = getMapDimensions(mainmapWidth, mainmapHeight);
		setMapWidth(width);
		setMapHeight(height);
	};

	const changeNumberOfRows = (value) => {
		const tmpBoxes = { ...boxes };
		if (nRows > value) {
			for (let i = nRows - 1; i >= value; i--) {
				for (let j = 0; j < nCols; j++) {
					delete tmpBoxes[`${i + 1}_${j + 1}`];
				}
			}
		} else {
			for (let i = nRows; i < value; i += 1) {
				for (let j = 0; j < nCols; j += 1) {
					tmpBoxes[`${i + 1}_${j + 1}`] = {
						r: i + 1,
						c: j + 1,
						rotation: 0,
						content: null,
						contentId: null,
						contentCat: null,
					};
				}
			}
		}

		setNRows(value);
		setBoxes(tmpBoxes);
		updateModel({ nRows: value, boxes: tmpBoxes });
	};

	const changeNumberOfColumns = (value) => {
		const tmpBoxes = { ...boxes };
		if (nCols > value) {
			for (let i = 0; i < nRows; i++) {
				for (let j = nCols - 1; j >= value; j--) {
					delete tmpBoxes[`${i + 1}_${j + 1}`];
				}
			}
		} else {
			for (let i = 0; i < nRows; i += 1) {
				for (let j = nCols; j < value; j += 1) {
					tmpBoxes[`${i + 1}_${j + 1}`] = {
						r: i + 1,
						c: j + 1,
						rotation: 0,
						content: null,
						contentId: null,
						contentCat: null,
					};
				}
			}
		}

		setNCols(value);
		setBoxes(tmpBoxes);
		updateModel({ nCols: value, boxes: tmpBoxes });
	};

	const changeGridVisible = () => {
		setGridVisible(!gridVisible);
		updateModel({ gridVisible: !gridVisible });
	};

	// eslint-disable-next-line unicorn/consistent-function-scoping
	const onMenuTileDragStart = (event) => {
		event.preventDefault();
	};

	const onMenuTileDrag = (event, comp, cat) => {
		event.preventDefault();
		setDragging(comp);
		setDraggingCat(cat);
		setDraggedItem({ x: event.pageX, y: event.pageY });
	};

	const onMenuTileDragStop = (event) => {
		event.preventDefault();

		const map = document.querySelector("#map");
		const { top, right, bottom, left } = map.getBoundingClientRect();
		const { x, y } = event;
		const tmpBoxes = { ...boxes };

		if (x >= left && x <= right && y >= top && y <= bottom) {
			for (const box of Object.keys(tmpBoxes)) {
				const comp = document.querySelector(`#box_${box}`);
				const { top: boxTop, right: boxRight, bottom: boxBottom, left: boxLeft } = comp.getBoundingClientRect();
				if (x >= boxLeft && x <= boxRight && y <= boxBottom && y >= boxTop) {
					tmpBoxes[box].content = categories[draggingCat].components[dragging].image;
					tmpBoxes[box].contentId = categories[draggingCat].components[dragging].id;
					tmpBoxes[box].contentCat = draggingCat;
					tmpBoxes[box].rotation = 0;
				}
			}
		}

		setBoxes(tmpBoxes);
		setDragging(null);
		setDraggingCat(null);

		updateModel({ boxes: tmpBoxes, dragging: null, draggingCat: null });
	};

	// eslint-disable-next-line unicorn/consistent-function-scoping
	const onMapTileDragStart = (event) => {
		event.preventDefault();
	};

	const onMapTileDrag = (event, comp) => {
		event.preventDefault();
		setCompDragging(comp);
	};

	const onMapTileClick = (comp) => {
		const tmpBoxes = { ...boxes };
		tmpBoxes[comp] = {
			...tmpBoxes[comp],
			rotation: (tmpBoxes[comp].rotation + 90) % 360,
		};

		setBoxes(tmpBoxes);
		updateModel({ boxes: tmpBoxes });
	};

	const onMapTileDragStop = (event, comp) => {
		event.preventDefault();

		if (compDraggingRef.current === null) {
			onMapTileClick(comp);
			return;
		}

		const map = document.querySelector("#map");
		const { top, right, bottom, left } = map.getBoundingClientRect();
		const { x, y } = event;
		const tmpBoxes = { ...boxes };

		if (x >= left && x <= right && y >= top && y <= bottom) {
			for (const box of Object.keys(tmpBoxes)) {
				const boxComp = document.querySelector(`#box_${box}`);
				const { top: boxTop, right: boxRight, bottom: boxBottom, left: boxLeft } = boxComp.getBoundingClientRect();
				if (x >= boxLeft && x <= boxRight && y <= boxBottom && y >= boxTop && box !== compDraggingRef.current) {
					tmpBoxes[box].content = tmpBoxes[compDraggingRef.current].content;
					tmpBoxes[box].contentId = tmpBoxes[compDraggingRef.current].contentId;
					tmpBoxes[box].contentCat = tmpBoxes[compDraggingRef.current].contentCat;
					tmpBoxes[box].rotation = tmpBoxes[compDraggingRef.current].rotation;
					tmpBoxes[compDraggingRef.current].content = null;
					tmpBoxes[compDraggingRef.current].contentId = null;
					tmpBoxes[compDraggingRef.current].contentCat = null;
					tmpBoxes[compDraggingRef.current].rotation = 0;
				}
			}
		} else {
			tmpBoxes[compDraggingRef.current].content = null;
			tmpBoxes[compDraggingRef.current].contentId = null;
			tmpBoxes[compDraggingRef.current].contentCat = null;
			tmpBoxes[compDraggingRef.current].rotation = 0;
		}

		setBoxes(tmpBoxes);
		setCompDragging(null);

		updateModel({ boxes: tmpBoxes, compDragging: null });
	};

	useEffect(() => {
		const modelJSON = JSON.parse(propsModel || "{}");
		setNRows(modelJSON?.nRows ?? 6);
		setNCols(modelJSON?.nCols ?? 9);
		setBoxes(modelJSON?.boxes ?? {});
		setGridVisible(modelJSON?.gridVisible ?? true);
		setMapWidth(modelJSON?.mapWidth ?? 100);
		setMapHeight(modelJSON?.mapHeight ?? 80);
		setCompDragging(null);
		setDragging(null);
		setDraggingCat(null);
		setDraggedItem({ x: 0, y: 0 });
		changeMapDimensions();
	}, [propsModel]);

	useEffect(() => {
		const tmpBoxes = { ...boxes };
		for (let i = 0; i < nRows; i++) {
			for (let j = 0; j < nCols; j++) {
				tmpBoxes[`${i + 1}_${j + 1}`] = {
					r: i + 1,
					c: j + 1,
					rotation: 0,
					content: null,
					contentId: null,
					contentCat: null,
				};
			}
		}

		setBoxes(tmpBoxes);

		changeMapDimensions();
		window.addEventListener("resize", changeMapDimensions);

		return () => {
			window.removeEventListener("resize", changeMapDimensions);
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const elementDimension = getElementDimension(mapWidth, mapHeight, nRows, nCols);
		const tmpGrid = [];
		for (const box of Object.keys(boxes)) {
			tmpGrid.push(
				<Draggable
					key={box}
					disabled={inPopup}
					allowAnyClick={false}
					position={{ x: 0, y: 0 }}
					onStart={onMapTileDragStart}
					onDrag={(event) => { onMapTileDrag(event, box); }}
					onStop={(event) => { onMapTileDragStop(event, box); }}
				>
					<div
						key={box}
						id={`box_${box}`}
						style={{
							display: "flex",
							width: elementDimension,
							height: elementDimension,
							border: (gridVisible) ? "1px solid #f1f2f2" : "",
							position: "absolute",
							left: ((mapWidth - nCols * elementDimension) / 2
								+ (boxes[box].c - 1) * elementDimension),
							top: ((mapHeight - nRows * elementDimension) / 2
								+ (boxes[box].r - 1) * elementDimension),
							cursor: "move",
							justifyContent: "center",
							zIndex: (compDragging === box) ? 1 : 0,
						}}
					>
						{boxes[box].content
							&& (
								<img src={boxes[box].content} alt="" style={{ maxWidth: "100%", maxHeight: "100%", transform: `rotate(${boxes[box].rotation}deg)` }} />
							)}
					</div>
				</Draggable>,
			);
		}

		setGrid(tmpGrid);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [boxes, gridVisible, mapWidth, mapHeight, nRows, nCols]);

	useEffect(() => {
		compDraggingRef.current = compDragging;
	}, [compDragging]);

	useEffect(() => {
		setInPopup(propsInPopup);
	}, [propsInPopup]);

	return (
		<Grid container className={classes.root}>
			<Grid item id="mainmap" xs={12} lg={inPopup ? 12 : 10} className={classes.mapRoot}>
				<Grid item id="map" className={classes.map} sx={{ width: `${mapWidth}px`, height: `${mapHeight}px` }}>
					{grid}
				</Grid>
			</Grid>
			<div
				style={{
					display: ((dragging === null) ? "none" : "flex"),
					left: `${draggedItem.x - 35}px`,
					top: `${draggedItem.y - 140}px`,
				}}
				className={classes.draggingTile}
			>
				{dragging !== null
					&& <img src={categories[draggingCat].components[dragging].image} alt="" style={{ maxWidth: "100%", maxHeight: "100%" }} />}
			</div>
			{!inPopup && (
				<Grid item xs={12} lg={2} className={classes.controlsRoot}>
					<Grid
						item
						width="100%"
						className={classes.sliderBox}
						sx={{ flexDirection: "row!important", justifyContent: "space-between!important", alignItems: "center" }}
					>
						<Typography textAlign="left">{"Grid Visible"}</Typography>
						<Switch
							checked={gridVisible}
							onChange={changeGridVisible}
						/>
					</Grid>
					<Grid item className={classes.sliderBox}>
						<Grid item className={classes.optionsRow}>
							<Typography textAlign="left">{"Rows"}</Typography>
							<Typography textAlign="right">{nRows}</Typography>
						</Grid>
						<Slider
							color="secondary"
							value={nRows}
							min={3}
							max={10}
							step={1}
							onChange={(event) => {
								changeNumberOfRows(event.target.value);
							}}
						/>
					</Grid>
					<Grid item className={classes.sliderBox}>
						<Grid item className={classes.optionsRow}>
							<Typography textAlign="left">{"Columns"}</Typography>
							<Typography textAlign="right">{nCols}</Typography>
						</Grid>
						<Slider
							color="secondary"
							value={nCols}
							min={3}
							max={15}
							step={1}
							onChange={(event) => {
								changeNumberOfColumns(event.target.value);
							}}
						/>
					</Grid>
					<Divider className={classes.divider} />
					{Object.keys(categories).map((cat) => (
						<Grid key={cat} width="100%" p={2}>
							<Accordion
								title={categories[cat].name}
								titleBackground="third"
								content={(
									<Grid container className={classes.tilesBox}>
										{Object.keys(categories[cat].components).map((comp) => (
											<Draggable
												key={comp}
												allowAnyClick={false}
												position={{ x: 0, y: 0 }}
												onStart={onMenuTileDragStart}
												onDrag={(event) => { onMenuTileDrag(event, comp, cat); }}
												onStop={onMenuTileDragStop}
											>
												<div style={{ opacity: (dragging === comp) ? 0 : 1 }} className={classes.menuTile}>
													<img src={categories[cat].components[comp].image} alt={comp} draggable={false} style={{ maxWidth: "100%", maxHeight: "100%" }} />
												</div>
											</Draggable>
										))}
									</Grid>
								)}
								alwaysExpanded={false}
							/>
						</Grid>
					))}
				</Grid>
			)}
		</Grid>
	);
};

export default CreateMap;
